import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Spin } from "antd";

import api from "../../services/Api";

import AuctionCard from "./AuctionCard";

import banner from "../../assets/images/banners/banneri-1920x400.png";
import banner2 from "../../assets/images/banners/banneri-1920x400_2.png";

const AuctionList = ({ filters, limit = 12, showBanners = false, loadMore = true }) => {
    const [auctions, setAuctions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [total, setTotal] = useState(0);

    const banners = [
        { image: banner, url: "https://www.sunholidays.fi/" },
        { image: banner2, url: "https://vaihtolomat.fi/" },
    ];

    // Ref for the loader element used for lazy loading
    const loaderRef = useRef(null);

    // Function to fetch auctions for a given page
    const fetchAuctions = async (pageNumber) => {
        setLoading(true);
        try {
            let response;

            if (loadMore) {
                response = await api.getAuctions({ filters, limit, page: pageNumber });
            } else {
                response = await api.getAuctions({ filters, limit });
            }

            if (loadMore) {
                if (pageNumber === 1) {
                    setAuctions(response.auctions);
                } else {
                    setAuctions((prevAuctions) => [...prevAuctions, ...response.auctions]);
                }
                if (response.auctions.length < limit) {
                    setHasMore(false);
                }
            } else {
                setAuctions(response.auctions);
            }

            setTotal(response.total);
        } catch (error) {
            console.error("Error fetching auctions:", error);
        } finally {
            setLoading(false);
        }
    };

    // When filters change, reset auctions, page and hasMore flag then fetch the first page
    useEffect(() => {
        if (loadMore) {
            setAuctions([]);
            setPage(1);
            setHasMore(true);
            fetchAuctions(1);
        } else {
            fetchAuctions();
        }
    }, [filters, limit, loadMore]);

    // Set up the IntersectionObserver for lazy loading
    useEffect(() => {
        if (!loadMore) return;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMore && !loading) {
                    setPage((prevPage) => prevPage + 1);
                }
            },
            {
                root: null,
                rootMargin: "20px",
                threshold: 1.0,
            }
        );
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }
        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [hasMore, loading, loadMore]);

    // Fetch auctions when the page number increments (except for the first page which is already fetched)
    useEffect(() => {
        if (page > 1) {
            fetchAuctions(page);
        }
    }, [page]);

    return (
        <div className="relative">
            {!loading && auctions.length === 0 ? (
                <div className="text-2xl text-gray-800 mt-5">Valitettavasti haku ei löytänyt yhtään kohdetta.</div>
            ) : (
                <div className="relative">
                    {/* For the initial load, display an overlay spinner */}
                    {loading && page === 1 && (
                        <div className="flex justify-center items-center absolute w-full bg-white bg-opacity-25 h-full z-20">
                            <Spin size="large" />
                        </div>
                    )}
                    <div className="text-2xl text-gray-800 mb-2 mt-5">
                        {loading && page === 1 ? (
                            <div className="flex items-center">
                                <Spin size="medium" className="mr-2" />
                                Päivitetään kohdelistaa...
                            </div>
                        ) : loadMore && (
                            <>
                                Löytyi {total} kohde{total > 1 && "tta"}
                            </>
                        )}
                    </div>
                    <div className="md:grid md:grid-cols-4 md:gap-5">
                        {auctions.map((auction, index) => (
                            <React.Fragment key={auction.number}>
                                <AuctionCard auction={auction} />
                                {showBanners && banners && (index === 3 || (index > 3 && (index - 3) % 8 === 0)) && (
                                    <>
                                        <div className="hidden md:block md:col-span-2">
                                            <Link to={banners[0].url} target="_blank">
                                                <img
                                                    src={banners[0].image}
                                                    alt="Banner"
                                                    className="w-full object-cover rounded"
                                                />
                                            </Link>
                                        </div>
                                        <div className="hidden md:block md:col-span-2">
                                            <Link to={banners[1].url} target="_blank">
                                                <img
                                                    src={banners[1].image}
                                                    alt="Banner"
                                                    className="w-full object-cover rounded"
                                                />
                                            </Link>
                                        </div>
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                    {/* Spinner for subsequent pages */}
                    {loadMore && loading && page > 1 && (
                        <div className="flex justify-center items-center my-4">
                            <Spin size="large" />
                        </div>
                    )}
                    {/* The element to observe for lazy loading */}
                    <div ref={loaderRef} />
                </div>
            )}
        </div>
    );
};

export default AuctionList;
