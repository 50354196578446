import React, { useCallback, useEffect } from "react";
import { Select, Button, InputNumber, Spin, Checkbox, Space } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const AuctionFilters = React.memo(({ filters, onFilterChange, areas, loadingAreas, areasError }) => {
    const handleInputChange = useCallback(
        (field, value) => {
            if (field === "beds") {
                if (value < 2 && value !== null) value = 2;
                if (value > 8) value = 8;
            }

            // Handle empty arrays correctly
            if (Array.isArray(value) && value.length === 0) {
                value = [];
            }

            const newFilters = {
                ...filters,
                [field]: value,
            };
            
            onFilterChange(newFilters);
            updateURLParams(newFilters);
        },
        [filters, onFilterChange]
    );

    const debounce = (func, delay) => {
        let timer;

        return function (...args) {
            const context = this;
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(context, args), delay);
        };
    };    

    const debouncedHandleInputChange = debounce(handleInputChange, 750);

    const handleReset = useCallback(() => {
        const defaultFilters = {
            types: ["sale", "rent", "auction"],
            areas: [],
            propertyWeeks: [],
            beds: null,
            priceMax: null,
            attributes: [],
            ownershipType: [],
        };
        
        onFilterChange(defaultFilters);
        
        // Clear URL parameters
        updateURLParams(defaultFilters, true);
    }, [onFilterChange]);
    
    // Function to update URL parameters
    const updateURLParams = (newFilters, isReset = false) => {
        const url = new URL(window.location);
        
        // Clear all existing filter parameters
        for (const key of [...url.searchParams.keys()]) {
            if (key.startsWith('filter_')) {
                url.searchParams.delete(key);
            }
        }
        
        if (!isReset) {
            // Add new parameters
            if (newFilters.types && newFilters.types.length) {
                url.searchParams.set('filter_types', newFilters.types.join(','));
            }
            
            if (newFilters.areas && newFilters.areas.length) {
                url.searchParams.set('filter_areas', newFilters.areas.join(','));
            }
            
            if (newFilters.propertyWeeks && newFilters.propertyWeeks.length) {
                url.searchParams.set('filter_weeks', newFilters.propertyWeeks.join(','));
            }
            
            if (newFilters.ownershipType && newFilters.ownershipType.length) {
                url.searchParams.set('filter_ownership', newFilters.ownershipType.join(','));
            }
            
            if (newFilters.beds) {
                url.searchParams.set('filter_beds', newFilters.beds);
            }
            
            if (newFilters.priceMax) {
                url.searchParams.set('filter_price_max', newFilters.priceMax);
            }
            
            if (newFilters.attributes && newFilters.attributes.length) {
                url.searchParams.set('filter_attrs', newFilters.attributes.join(','));
            }
        }
        
        // Update the URL without reloading the page
        window.history.pushState({}, '', url);
    };
    
    // Parse URL parameters on component mount - with higher priority
    useEffect(() => {
        // Function to parse URL parameters
        const parseURLParams = () => {
            const url = new URL(window.location);
            
            // Start with default filters structure
            const defaultFilters = {
                types: ["sale", "rent", "auction"],
                areas: [],
                propertyWeeks: [],
                beds: null,
                priceMax: null,
                attributes: [],
                ownershipType: [],
            };
            
            // Check if we have any URL parameters
            let hasUrlParams = false;
            for (const key of [...url.searchParams.keys()]) {
                if (key.startsWith('filter_')) {
                    hasUrlParams = true;
                    break;
                }
            }
            
            // If no URL parameters, don't override the default filters
            if (!hasUrlParams) {
                return;
            }
            
            // Parse types
            const typesParam = url.searchParams.get('filter_types');
            if (typesParam) {
                defaultFilters.types = typesParam.split(',');
            }
            
            // Parse areas
            const areasParam = url.searchParams.get('filter_areas');
            if (areasParam) {
                defaultFilters.areas = areasParam.split(',');
            }
            
            // Parse property weeks
            const weeksParam = url.searchParams.get('filter_weeks');
            if (weeksParam) {
                defaultFilters.propertyWeeks = weeksParam.split(',');
            }
            
            // Parse ownership types
            const ownershipParam = url.searchParams.get('filter_ownership');
            if (ownershipParam) {
                defaultFilters.ownershipType = ownershipParam.split(',');
            }
            
            // Parse beds
            const bedsParam = url.searchParams.get('filter_beds');
            if (bedsParam) {
                defaultFilters.beds = parseInt(bedsParam);
            }
            
            // Parse max price
            const priceMaxParam = url.searchParams.get('filter_price_max');
            if (priceMaxParam) {
                defaultFilters.priceMax = parseInt(priceMaxParam);
            }
            
            // Parse attributes
            const attrsParam = url.searchParams.get('filter_attrs');
            if (attrsParam) {
                defaultFilters.attributes = attrsParam.split(',');
            }
            
            // Apply parsed filters
            onFilterChange(defaultFilters);
        };
        
        // We need to run this immediately, before any API calls
        parseURLParams();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const typeOptions = [
        { label: "Myytävät", value: "sale" },
        { label: "Vuokrattavat", value: "rent" },
        { label: "Huutokaupat", value: "auction" },
    ];

    const propertyWeekOptions = [
        { label: "2-7", value: "2-7" },
        { label: "8-18", value: "8-18" },
        { label: "19-32", value: "19-32" },
        { label: "33-51", value: "33-51" },
        { label: "52-1", value: "52-1" },
    ];

    const attributesOptions = [
        { label: "Sauna", value: "hasSauna" },
        { label: "Parveke / Terassi", value: "hasBalcony" },
    ];

    const propertyOwnershipTypeOptions = [
        { label: "Viikko", value: "week" },
        { label: "Osaomistus", value: "parttime" },
        { label: "Muu omistus", value: "other" },
    ];

    useEffect(() => {
        if (filters.types.includes("auction") && filters.priceMax !== null) {
            handleInputChange("priceMax", null);
        }
    }, [filters.types, filters.priceMax, handleInputChange]);

    return (
        <div className="flex flex-wrap gap-5 select-none">
            {/* Type Selector */}
            <div className="w-40">
                <div className="font-bold mb-2">Ilmoitukset</div>
                <Checkbox.Group
                    value={filters.types}
                    onChange={(value) => handleInputChange("types", value)}
                    className="w-full"
                >
                    <Space direction="vertical">
                        {typeOptions.map((option) => (
                            <div key={option.value}>
                                <Checkbox value={option.value} className="mr-2">
                                    <span className="font-normal cursor-pointer hover:font-semibold">
                                        {option.label}
                                    </span>
                                </Checkbox>
                            </div>
                        ))}
                    </Space>
                </Checkbox.Group>
            </div>

            {/* Area Selector */}
            <div className="w-40">
                <div className="font-bold mb-2">Sijainti</div>
                {loadingAreas ? (
                    <Spin />
                ) : areasError ? (
                    <div className="text-red-500">{areasError}</div>
                ) : (
                    <Select
                        mode="multiple"
                        value={filters.areas || []}
                        onChange={(value) => handleInputChange("areas", value)}
                        allowClear
                        onClear={() => handleInputChange("areas", [])}
                        className="w-full h-8"
                        maxTagCount={"responsive"}
                    >
                        {areas?.map((area) => (
                            <Option key={area.area} value={area.area} disabled={area.auctions === 0}>
                                {area.area}
                            </Option>
                        ))}
                    </Select>
                )}
            </div>

            {/* Property Week Selector */}
            <div className="w-40">
                <div className="font-bold mb-2">Viikko</div>
                <Checkbox.Group
                    value={filters.propertyWeeks || []}
                    onChange={(value) => handleInputChange("propertyWeeks", value)}
                    className="w-full"
                >
                    <div className="grid grid-flow-col grid-rows-3">
                        {propertyWeekOptions.map((option) => (
                            <div key={option.value}>
                                <Checkbox value={option.value} className="mr-2">
                                    <span className="font-normal cursor-pointer hover:font-semibold">
                                        {option.label}
                                    </span>
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                </Checkbox.Group>
            </div>

            {/* Property Week Selector */}
            <div className="w-40">
                <div className="font-bold mb-2">Omistusmuoto</div>
                <Checkbox.Group
                    value={filters.ownershipType || []}
                    onChange={(value) => handleInputChange("ownershipType", value)}
                    className="w-full"
                >
                    <div className="grid grid-flow-col grid-rows-3">
                        {propertyOwnershipTypeOptions.map((option) => (
                            <div key={option.value}>
                                <Checkbox value={option.value} className="mr-2">
                                    <span className="font-normal cursor-pointer hover:font-semibold">
                                        {option.label}
                                    </span>
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                </Checkbox.Group>
            </div>

            {/* Bed Selector */}
            <div className="w-40 select-none relative">
                <div className="font-bold mb-2">Vuodepaikat min</div>
                <div className="flex items-center">
                    <Button
                        icon={<MinusOutlined />}
                        onClick={() => handleInputChange("beds", (filters.beds || 2) - 1)}
                        disabled={(filters.beds || 2) <= 2}
                        className="size-8"
                    />
                    <div className="w-12 text-center">{filters.beds || "2"}</div>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => handleInputChange("beds", (filters.beds || 2) + 1)}
                        disabled={(filters.beds || 2) >= 8}
                        className="size-8"
                    />
                </div>
            </div>

            {/* Attributes Selector */}
            <div className="w-40">
                <div className="font-bold mb-2">Ominaisuudet</div>
                <Checkbox.Group
                    value={filters.attributes || []}
                    onChange={(value) => handleInputChange("attributes", value)}
                    className="w-full"
                >
                    <Space direction="vertical">
                        {attributesOptions.map((option) => (
                            <div key={option.value}>
                                <Checkbox value={option.value} className="mr-2">
                                    <span className="font-normal cursor-pointer hover:font-semibold">
                                        {option.label}
                                    </span>
                                </Checkbox>
                            </div>
                        ))}
                    </Space>
                </Checkbox.Group>
            </div>

            {/* Conditionally Render Price Max Input */}
            {!filters.types.includes("auction") && (
                <div className="w-40">
                    <div className="font-bold mb-2">Hinta max</div>
                    <InputNumber
                        min={0}
                        max={1000000}
                        value={filters.priceMax}
                        onChange={(value) => debouncedHandleInputChange("priceMax", value)}
                        className="w-full"
                        size="large"
                        placeholder="Hinta max"
                    />
                </div>
            )}

            {/* Apply and Reset Buttons */}
            <div className="flex items-end justify-end flex-grow">
                <Button onClick={handleReset} className="">
                    Tyhjennä
                </Button>
            </div>
        </div>
    );
});

export default AuctionFilters;