import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import api from "../../services/Api";
import { DatePicker, Form, Input, Pagination, Select, Table, Tooltip, message } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Helsinki");

const Auctions = () => {
    const [auctions, setAuctions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(99999);
    const [totalAuctions, setTotalAuctions] = useState(0);
    const [type, setType] = useState("");
    const [status, setStatus] = useState("published");
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        setLoading(true);

        const fetchAuctions = async () => {
            try {
                const data = await api.getAdminAuctions(status, type, searchTerm, currentPage, pageSize);
                setAuctions(data.items);
                setTotalAuctions(data.total);
            } catch (error) {
                console.error("Error fetching auctions:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAuctions();
    }, [status, type, searchTerm, currentPage, pageSize]);

    const token = localStorage.getItem("token");

    if (!token) {
        return <Navigate to="/" />;
    }

    const statusColors = ["#f9a826", "#3bb54a", "#d0021b", "#b0b0b0", "#ee4400"];
    const statusNames = ["Luonnos", "Julkaistu", "Myyty", "Päättynyt", "Peruutettu"];
    const statusLabels = {};

    statusNames.forEach((name, index) => {
        statusLabels[name] = statusColors[index];
    });

    const typeColors = ["#01696e", "#6e5201", "#a002c6"];
    const typeNames = ["Huutokauppa", "Myynti", "Vuokraus"];
    const typeLabels = {};

    typeNames.forEach((name, index) => {
        typeLabels[name] = typeColors[index];
    });

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleTypeChange = (value) => {
        setType(value);
    };

    const handleStatusChange = (value) => {
        setStatus(value);
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const columns = [
        {
            title: "#",
            dataIndex: "number",
            key: "number",
            sorter: (a, b) => a.number - b.number,
            defaultSortOrder: "descend",
        },
        {
            title: "Kohde",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Huoneisto",
            dataIndex: "roomNumber",
            key: "roomNumber",
        },
        {
            title: "Vko",
            dataIndex: "propertyWeek",
            key: "propertyWeek",
        },
        {
            title: "Käyttäjä",
            dataIndex: "userName",
            key: "userName",
            sorter: (a, b) => a.userName.localeCompare(b.userName),
            render: (text, record) => {
                return (
                    <Link to={`/hallinta/kayttajat/${record.id}`} className="text-blue-700 underline">
                        {text}
                    </Link>
                );
            },
        },
        {
            title: "",
            dataIndex: "typeText",
            key: "typeText",
            render: (text) => {
                return (
                    <Tooltip title={text}>
                        <div
                            className="rounded w-5 h-5 text-white flex items-center justify-center font-extrabold"
                            style={{ backgroundColor: typeLabels[text] }}
                        >
                            {text.slice(0, 1).toUpperCase()}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: "",
            dataIndex: "statusText",
            key: "statusText",
            render: (text) => {
                return (
                    <Tooltip title={text}>
                        <div
                            className="rounded w-5 h-5 text-white flex items-center justify-center font-extrabold"
                            style={{ backgroundColor: statusLabels[text] }}
                        >
                            {text.slice(0, 1).toUpperCase()}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: "Myyntihinta",
            dataIndex: "salePrice",
            key: "salePrice",
            render: (text) => {
                return (
                    <>
                        {text}
                        {text && <> &euro;</>}
                    </>
                );
            },
        },
        {
            title: "Luotu",
            dataIndex: "createdAt",
            key: "createdAt",
        },
        {
            title: "Etusivu",
            dataIndex: "featuredUntil",
            key: "featuredUntil",
            render: (text, record) => {
                const dateValue = record.featuredUntil ? dayjs(record.featuredUntil, "YYYY-MM-DD") : null; // Convert to dayjs
                return dateValue ? (
                    <div className="flex items-center">
                        <DatePicker
                            value={dateValue}
                            format="D.M.YYYY"
                            onChange={(date, dateString) => handleUpdateFeaturedUntil(record.uuid, dateString)}
                        />
                        <MinusCircleOutlined
                            className="text-red-600 ml-2"
                            onClick={() => handleUpdateFeaturedUntil(record.uuid, "")}
                        />
                    </div>
                ) : (
                    <PlusCircleOutlined className="text-gray-500" onClick={() => handleAddFeaturedDate(record.uuid)} />
                );
            },
        },
        {
            title: "",
            key: "actions",
            width: 50,
            render: (text, record) => (
                <Link to={`/oma-sivu/ilmoitukset/${record.uuid}`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                    </svg>
                </Link>
            ),
        },
    ];

    const handleUpdateFeaturedUntil = async (auctionUuid, dateString) => {
        try {
            const response = await api.updateFeaturedUntil(auctionUuid, { featuredUntil: dateString });

            const updatedAuctions = auctions.map((auction) =>
                auction.uuid === auctionUuid ? { ...auction, featuredUntil: response } : auction
            );

            setAuctions(updatedAuctions);
            message.success("Etusivun näkyvyys tallennettu.");
        } catch (error) {
            console.error("Error updating featured date:", error);
            message.error("Failed to update featured date.");
        }
    };

    const handleAddFeaturedDate = async (auctionUuid) => {
        try {
            const newDate = dayjs().format("YYYY-MM-DD"); // Default date
            const response = await api.updateFeaturedUntil(auctionUuid, { featuredUntil: newDate });

            const updatedAuctions = auctions.map((auction) =>
                auction.uuid === auctionUuid
                    ? { ...auction, featuredUntil: response.featuredUntil || newDate }
                    : auction
            );

            setAuctions(updatedAuctions);
            message.success("Etusivun näkyvyys tallennettu.");
        } catch (error) {
            console.error("Error adding featured date:", error);
            message.error("Failed to add featured date.");
        }
    };

    return (
        <>
            <Link to="/oma-sivu/ilmoitukset/uusi">
                <div className="text-white bg-green-600 px-5 py-2 rounded hover:bg-green-500 hover:text-white transition-colors duration-200 inline-block float-right">
                    Lisää uusi ilmoitus
                </div>
            </Link>

            <h1 className="text-2xl mb-5">Ilmoitukset</h1>

            <div className="">
                <div className="flex justify-between mb-3">
                    <div className="flex">
                        <Form.Item label="Tila" className="w-44">
                            <Select className="w-44" onChange={handleStatusChange} value={status}>
                                <Select.Option value="">Kaikki</Select.Option>
                                <Select.Option value="draft">Luonnos</Select.Option>
                                <Select.Option value="published">Julkaistu</Select.Option>
                                <Select.Option value="sold">Myyty</Select.Option>
                                <Select.Option value="expired">Vanhentunut</Select.Option>
                                <Select.Option value="cancelled">Peruttu</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className="w-44 ml-5" label="Tyyppi">
                            <Select className="w-44" onChange={handleTypeChange} value={type}>
                                <Select.Option value="">Kaikki</Select.Option>
                                <Select.Option value="auction">Huutokauppa</Select.Option>
                                <Select.Option value="sale">Myynti</Select.Option>
                                <Select.Option value="rent">Vuokraus</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className="w-64 ml-5" label="Haku">
                            <Input className="w-44" onKeyUp={handleSearchTermChange} />
                        </Form.Item>
                    </div>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalAuctions}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                        className="mt-4"
                    />
                </div>
                <Table dataSource={auctions} columns={columns} rowKey="id" pagination={true} loading={loading} />
            </div>
            {/* <div className="flex justify-end">
                <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalAuctions}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    className="mt-4"
                />
            </div> */}
        </>
    );
};

export default Auctions;
