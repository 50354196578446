import React, { useState, useEffect } from "react";

import { Table, Tabs } from 'antd';

import AuctionOfferModal from "../../components/modals/AuctionOfferModal";
import api from "../../services/Api";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Link } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Helsinki");
const Offers = () => {
    const [loading, setLoading] = useState(true);
    const [offers, setOffers] = useState({
        pendingDeals: [],
        completedSells: [],
        acceptedBids: [],
    });

    const statusColors = {
        "accepted": "bg-green-500",
        "pending": "bg-yellow-500",
        "rejected": "bg-red-500",
    }

    useEffect(() => {
        const fetchOffers = async () => {
            try {
                setLoading(true);
                const data = await api.getOffers();
                setOffers(data || {
                    pendingDeals: [],
                    completedSells: [],
                    acceptedBids: [],
                });
            } catch (error) {
                console.error("Error fetching offers:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchOffers();
    }, []);

    const formatDate = (dateString) => {
        return dateString
            ? dayjs(dateString).format("DD.MM.YYYY HH:mm")
            : "-";
    };

    const pendingDealsColumns = [
        {
            title: "Kohde",
            key: "auction",
            render: (_, record) => (
                <div>
                    #{record.auction.number} {record.auction.title}
                </div>
            ),
        },
        {
            title: "Myyjä",
            key: "seller",
            render: (_, record) => (
                <Link to={`/hallinta/kayttajat/${record.auction.user?.id}`} className="text-blue-700">{record.auction.user?.name || "-"}</Link>
            ),
        },
        {
            title: "Ostaja",
            key: "buyer",
            render: (_, record) => (
                <Link to={`/hallinta/kayttajat/${record.buyer?.id}`} className="text-blue-700">{record.buyer?.name || "-"}</Link>
            ),
        },
        {
            title: <div className="text-right">Myyntihinta</div>,
            dataIndex: "amount",
            key: "amount",
            render: (amount) => <div className="text-right">{amount || "-"}€</div>,
        },
        {
            title: "Hyväksytty",
            key: "acceptedAt",
            render: (_, record) => (
                <div>{formatDate(record.auction.ends.date)}</div>
            ),
        },
    ];

    const completedSellsColumns = [
        {
            title: "Kohde",
            key: "auction",
            render: (_, record) => (
                <div>
                    #{record.auction.number} {record.auction.title}
                </div>
            ),
        },
        {
            title: "Myyjä",
            key: "seller",
            render: (_, record) => (
                <Link to={`/hallinta/kayttajat/${record.seller?.id}`} className="text-blue-700">{record.seller?.name || "-"}</Link>
            ),
        },
        {
            title: "Ostaja",
            key: "buyer",
            render: (_, record) => (
                <Link to={`/hallinta/kayttajat/${record.buyer?.id}`} className="text-blue-700">{record.buyer?.name || "-"}</Link>
            ),
        },
        {
            title: <div className="text-right">Myyntihinta</div>,
            dataIndex: "amount",
            key: "amount",
            render: (amount) => <div className="text-right">{amount || "-"}€</div>,
        },
        {
            title: <div className="text-right">Komissio</div>,
            key: "commission",
            render: (_, record) => (
                <div className="text-right">
                    {record.contract?.commissionTotal || "-"}€
                </div>
            ),
        },
        {
            title: "Viimeistelty",
            key: "finalizedAt",
            render: (_, record) => (
                <div>{formatDate(record.auction.finalizedAt.date)}</div>
            ),
        },
    ];

    const acceptedBidsColumns = [
        {
            title: "Kohde",
            key: "auction",
            render: (_, record) => (
                <div>
                    #{record.auction.number} {record.auction.title}
                </div>
            ),
        },
        {
            title: "Myyjä",
            key: "seller",
            render: (_, record) => (
                <Link to={`/hallinta/kayttajat/${record.seller?.id}`} className="text-blue-700">{record.seller?.name || "-"}</Link>
            ),
        },
        {
            title: "Ostaja",
            key: "buyer",
            render: (_, record) => (
                <Link to={`/hallinta/kayttajat/${record.buyer?.id}`} className="text-blue-700">{record.buyer?.name || "-"}</Link>
            ),
        },
        {
            title: <div className="text-right">Tarjoushinta</div>,
            dataIndex: "amount",
            key: "amount",
            render: (amount) => <div className="text-right">{amount || "-"}€</div>,
        },
        {
            title: "Hyväksytty",
            key: "acceptedAt",
            render: (_, record) => (
                <div>{formatDate(record.auction.ends.date)}</div>
            ),
        },
    ];

    const allBidsColumns = [
        {
            title: "Pvm",
            key: "createdAt",
            render: (_, record) => (
                <div>{record.createdAt}</div>
            ),
        },
        {
            title: "Kohde",
            key: "auction",
            render: (_, record) => (
                <Link to={`/oma-sivu/ilmoitukset/${record.auction.uuid}`} target="_blank" className="text-blue-700">
                    #{record.auction.number} {record.auction.title}
                </Link>
            ),
        },
        {
            title: "Tyyppi",
            key: "type",
            render: (_, record) => (
                <div>{record.auction.typeText}</div>
            ),
        },
        {
            title: "Tarjoaja",
            key: "bidder",
            render: (_, record) => (
                <Link to={`/hallinta/kayttajat/${record.user?.id}`} className="text-blue-700">{record.user?.name || "-"}</Link>
            ),
        },
        {
            title: <div className="text-right">Summa</div>,
            dataIndex: "amount",
            key: "amount",
            render: (amount) => <div className="text-right">{amount || "-"}€</div>,
        },
        {
            title: "Myyjä",
            key: "seller",
            render: (_, record) => (
                <Link to={`/hallinta/kayttajat/${record.auction.user?.id}`} className="text-blue-700">{record.auction.user?.name || "-"}</Link>
            ),
        },
        {
            title: "Status",
            key: "status",
            render: (_, record) => (
                <div className={`text-center text-white rounded p-1 ${statusColors[record.status]}`}>
                    {record.statusText}
                </div>
            )
        }
    ];

    const items = [
        {
            label: `Hyväksytyt tarjoukset (${offers.acceptedBids.length})`,
            key: "acceptedBids",
            children: (
                <Table
                    dataSource={offers.acceptedBids}
                    columns={acceptedBidsColumns}
                    rowKey="id"
                    pagination={true}
                    loading={loading}
                    size="small"
                />
            ),
        },
        {
            label: `Odottaa kauppoja (${offers.pendingDeals.length})`,
            key: "pendingDeals",
            children: (
                <Table
                    dataSource={offers.pendingDeals}
                    columns={pendingDealsColumns}
                    rowKey="id"
                    pagination={true}
                    loading={loading}
                    size="small"
                />
            ),
        },
        {
            label: `Viimeistellyt kaupat (${offers.completedSells.length})`,
            key: "completedSells",
            children: (
                <Table
                    dataSource={offers.completedSells}
                    columns={completedSellsColumns}
                    rowKey="id"
                    pagination={true}
                    loading={loading}
                    size="small"
                />
            ),
        },
        {
            label: `Kaikki tarjoukset (${offers.allBids?.length})`,
            key: "allBids",
            children: (
                <Table
                    dataSource={offers.allBids}
                    columns={allBidsColumns}
                    rowKey="id"
                    pagination={true}
                    loading={loading}
                    size="small"
                />
            ),
        },
    ];    

    return (
        <>
            <h1 className="text-2xl mb-5">Tarjoukset</h1>
            <Tabs defaultActiveKey="acceptedBids" items={items} />
        </>
    );
};

export default Offers;