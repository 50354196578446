import React, { useState, useContext, useEffect } from "react";

import ProfileEditModal from "../../components/modals/ProfileEditModal";
import PasswordChangeModal from "../../components/modals/PasswordChangeModal";

import { Button, Input, message, Modal, Popconfirm, Spin, Tooltip } from "antd";
import {
    CalendarOutlined,
    DownloadOutlined,
    MailOutlined,
    PhoneOutlined,
    QuestionCircleFilled,
    UserOutlined,
} from "@ant-design/icons";

import { AuctionTypeLabels } from "../../constants/AuctionTypes";
import { AuthContext } from "../../context/AuthContext";

import api from "../../services/Api";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Profile = () => {
    const { user, hasRole } = useContext(AuthContext);
    const [isProfileEditModalOpen, setIsProfileEditModalOpen] = useState(false);
    const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
    const [auctionOffers, setAuctionOffers] = useState([]);
    const [auctionOffersLoading, setAuctionOffersLoading] = useState(false);
    const [approvingOffer, setApprovingOffer] = useState(0);
    const [paymentConfirmModalOpen, setPaymentConfirmModalOpen] = useState(false);
    const [paymentConfirmAuctionUuid, setPaymentConfirmAuctionUuid] = useState("");
    const [paymentConfirmAuctionAmount, setPaymentConfirmAuctionAmount] = useState("");
    const [paymentConfirmRole, setPaymentConfirmRole] = useState("");
    const [confirmingOffer, setConfirmingOffer] = useState(false);

    const currentUrl = `${window.location.origin}${window.location.pathname}`;

    const fetchAuctionOffers = async () => {
        setAuctionOffersLoading(true);

        try {
            const response = await api.getAuctionOffers();
            setAuctionOffers(response);
        } catch (error) {
            //console.error(error);
        } finally {
            setAuctionOffersLoading(false);
        }
    };

    useEffect(() => {
        fetchAuctionOffers();
    }, []);

    const showProfileEditModal = () => {
        setIsProfileEditModalOpen(true);
    };

    const closeProfileEditModal = () => {
        setIsProfileEditModalOpen(false);
    };

    const showPasswordChangeModal = () => {
        setIsPasswordChangeModalOpen(true);
    };

    const closePasswordChangeModal = () => {
        setIsPasswordChangeModalOpen(false);
    };

    const handleAcceptOffer = async (offerId) => {
        setApprovingOffer(offerId);

        try {
            await api.acceptOffer(offerId);
            fetchAuctionOffers();
        } catch (error) {
            console.error(error);
        } finally {
            setApprovingOffer(0);
        }
    };

    const handleRejectOffer = async (offerId) => {
        setApprovingOffer(offerId);

        try {
            await api.rejectOffer(offerId);
            fetchAuctionOffers();
        } catch (error) {
            console.error(error);
        } finally {
            setApprovingOffer(0);
        }
    };

    const handleAuthentication = () => {
        window.location.href = user.authUrl;
    };

    const sendEmail = async () => {
        try {
            await api.sendEmail();
        } catch (error) {
            console.error(error);
        }
    };

    const handleCommissionPayment = async (auctionUuid, commissionTotal) => {
        const payload = {
            productCode: "sales-commission",
            price: commissionTotal,
            redirectUrl: `${window.location.origin}/oma-sivu`,
            auctionUuid: auctionUuid,
        };

        try {
            const response = await api.createPayment(payload);

            document.location.href = response.href;
        } catch (error) {
            console.error("Error during payment processing: ", error);
        }
    };

    const handleConfirmPayment = async () => {
        setConfirmingOffer(true);

        try {
            await api.confirmAuctionPayment(paymentConfirmAuctionUuid, paymentConfirmAuctionAmount);
        } catch (error) {
            console.error(error);
        } finally {
            setPaymentConfirmModalOpen(false);
            setPaymentConfirmAuctionUuid("");
            setPaymentConfirmAuctionAmount("");
            fetchAuctionOffers();
            setConfirmingOffer(false);
        }
    };

    const openConfirmPaymentModal = (auctionUuid, offerAmount, role) => {
        setPaymentConfirmAuctionUuid(auctionUuid);
        setPaymentConfirmAuctionAmount(offerAmount);
        setPaymentConfirmRole(role);
        setPaymentConfirmModalOpen(true);
    };

    function handleFileDownload(event, fileId, fileDescription) {
        event.preventDefault(); // Prevent the default link action

        fetch(`${URL}/lataa/${fileId}`, { method: "HEAD" }) // Use HEAD to check if the file exists
            .then((response) => {
                if (response.ok) {
                    window.open(`${URL}/lataa/${fileId}`, "_blank", "noopener,noreferrer");
                } else {
                    message(`Sorry, the file "${fileDescription}" is not available.`);
                }
            })
            .catch(() => {
                message.error("Virhe tiedoston latauksessa. Yritä myöhemmin uudelleen.");
            });
    }

    return (
        <>
            <Helmet>
                <title>Oma tili - Lomahuuto.fi</title>
                <meta
                    name="description"
                    content="Lomahuuto.fi tarjoaa helppokäyttöisen ja keskitetyn markkinapaikan lomaosakkeille."
                />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Lomahuuto.fi" />
                <meta
                    property="og:description"
                    content="Lomahuuto.fi tarjoaa helppokäyttöisen ja keskitetyn markkinapaikan lomaosakkeille."
                />
            </Helmet>
            <ProfileEditModal user={user.uuid} open={isProfileEditModalOpen} onClose={closeProfileEditModal} />
            <PasswordChangeModal open={isPasswordChangeModalOpen} onClose={closePasswordChangeModal} />

            {/* {user.verified === false && ( */}
            {hasRole(user.roles, "ROLE_GOD") && (
                <div className="bg-yellow-200 p-5 mb-5 flex items-center justify-between">
                    <div>
                        <div className="text-lg font-bold">Tiliäsi ei ole vielä vahvistettu</div>
                        <div className="text-base">
                            Jotta voit tehdä tarjouksia tai jättää ilmoituksia, tulee sinun tunnistautua
                            pankkitunnuksilla.
                        </div>
                    </div>
                    <Button type="primary" size="large" onClick={handleAuthentication}>
                        Tunnistaudu
                    </Button>
                </div>
            )}

            <div className="md:flex justify-between items-start">
                <div>
                    <h1 className="text-2xl mb-0 font-normal">{user.name}</h1>
                    <h2 className="mb-5 font-normal">{user.email}</h2>
                </div>
                <div className="md:space-x-2 md:text-right flex justify-between">
                    {/* {hasRole(user.roles, "ROLE_GOD") && (
                        <Button type="primary" onClick={sendEmail}>
                            Lähetä sähköposti
                        </Button>
                    )} */}

                    <Button type="primary" onClick={showProfileEditModal}>
                        Muokkaa profiilia
                    </Button>
                    <Button type="primary" onClick={showPasswordChangeModal}>
                        Vaihda salasana
                    </Button>
                </div>
            </div>
            <hr className="md:border-bottom md:border-gray-400" />
            <div className="mt-5">
                {auctionOffersLoading ? (
                    <Spin />
                ) : (
                    <>
                        {auctionOffers.liveAuctions?.length > 0 && (
                            <div className="mb-5">
                                <h2 className="text-xl font-bold">Avoimet huutokaupat</h2>
                                <div className="text-sm grid md:grid-cols-3 grid-cols-1 gap-5">
                                    {auctionOffers.liveAuctions.map((auction) => (
                                        <div key={auction.uuid} className="shadow-md p-3">
                                            <div className="text-base font-bold uppercase -m-3 bg-gray-200 p-3 mb-3">
                                                {AuctionTypeLabels[auction.type]}
                                            </div>
                                            <div className="space-y-2">
                                                <div>
                                                    <div className="text-sm">Kohde</div>#{auction.number}{" "}
                                                    {auction.title}
                                                </div>
                                                <div>
                                                    <div className="text-sm">Päättyy</div>
                                                    {auction.endsAt}
                                                </div>
                                                <div>
                                                    <div className="text-sm">Korkein tarjous</div>
                                                    {auction.bids.highestBid
                                                        ? auction.bids.highestBid.amount +
                                                          " € (" +
                                                          new Date(
                                                              auction.bids.highestBid.createdAt.date
                                                          ).toLocaleString("FI-fi") +
                                                          ")"
                                                        : "Ei tarjouksia"}{" "}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {auctionOffers.pendingOffers?.length > 0 && (
                            <div className="mb-5">
                                <h2 className="text-xl font-bold">Avoimet tarjoukset</h2>

                                <div className="text-lg grid grid-cols-2 gap-5">
                                    {auctionOffers.pendingOffers.map((offer) => (
                                        <div key={offer.auction.uuid} className="shadow-md p-3 relative">
                                            {approvingOffer === offer.id && (
                                                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-5 flex items-center justify-center">
                                                    <Spin />
                                                </div>
                                            )}
                                            <div className="text-lg font-bold uppercase -m-3 bg-gray-200 p-3 mb-3">
                                                {AuctionTypeLabels[offer.auction.type]}
                                            </div>
                                            <div className="space-y-2">
                                                <div>
                                                    <div className="text-sm">Kohde</div>#{offer.auction.number}{" "}
                                                    {offer.auction.title}
                                                </div>
                                                <div>
                                                    <div className="text-sm">Tarjous annettu</div>
                                                    {offer.createdAt}
                                                </div>
                                                <div>
                                                    <div className="text-sm">
                                                        {offer.auction.type === "auction"
                                                            ? "Korkein tarjous"
                                                            : "Tarjous"}
                                                    </div>
                                                    {offer.amount} &euro;
                                                </div>
                                            </div>
                                            <div className="flex justify-between mt-5">
                                                <Button
                                                    type="primary"
                                                    danger
                                                    onClick={() => handleRejectOffer(offer.id)}
                                                >
                                                    Hylkää
                                                </Button>
                                                <Button type="primary" onClick={() => handleAcceptOffer(offer.id)}>
                                                    Hyväksy
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {auctionOffers.pendingDeals?.length > 0 && (
                            <div className="mb-5">
                                <h2 className="text-xl font-bold">Hyväksytyt tarjoukset</h2>

                                <div className="text-lg grid md:grid-cols-2 grid-cols-1 gap-5">
                                    {auctionOffers.pendingDeals.map((offer) => (
                                        <div key={offer.auction.uuid} className="shadow-md p-3">
                                            <div className="text-lg font-bold uppercase -m-3 bg-gray-200 p-3 mb-3">
                                                {AuctionTypeLabels[offer.auction.type]}
                                            </div>
                                            <div className="space-y-2">
                                                <div>
                                                    <div className="text-sm">Kohde</div>#{offer.auction.number}{" "}
                                                    {offer.auction.title}
                                                </div>
                                                <div>
                                                    <div className="text-sm">Tarjouksen tiedot</div>
                                                    <div className="text-sm">
                                                        <div className="flex items-center text-lg">
                                                            <UserOutlined className="mr-2" /> {offer.buyer.name}
                                                        </div>
                                                        <div className="flex items-center">
                                                            <MailOutlined className="mr-2" /> {offer.buyer.email}
                                                        </div>
                                                        <div className="flex items-center">
                                                            <PhoneOutlined className="mr-2" /> {offer.buyer.phone}
                                                        </div>
                                                        <div className="flex items-center">
                                                            <CalendarOutlined className="mr-2" /> {offer.createdAt}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <div className="text-sm">
                                                            {offer.auction.type === "auction"
                                                                ? "Korkein tarjous"
                                                                : "Tarjous"}
                                                        </div>
                                                        {offer.amount} &euro;
                                                    </div>

                                                    <div className="flex flex-col items-end gap-2 md:flex-row md:items-center">
                                                        <div className="flex items-center">
                                                            <Tooltip title="Luo kauppakirja tai vuokrasopimus palvelussamme">
                                                                <Link to={`/oma-sivu/sopimus/${offer.auction.uuid}`}>
                                                                    <Button type="primary">
                                                                        Luo{" "}
                                                                        {offer.auction.type === "rent"
                                                                            ? "vuokrasopimus"
                                                                            : "kauppakirja"}
                                                                    </Button>
                                                                </Link>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title="Luo kauppakirja tai vuokrasopimus palvelussamme"
                                                                className="md:hidden"
                                                            >
                                                                <div className="text-lg">
                                                                    <QuestionCircleFilled className="text-blue-700 ml-2" />
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                        <span className="mx-2 md:block hidden">tai</span>
                                                        <div className="flex items-center">
                                                            <Tooltip title="Kuittaa maksu suoritetuksi ja ilmoita toteutunut kauppahinta myyntiprovision maksamista varten.">
                                                                <Button
                                                                    type="primary"
                                                                    onClick={() =>
                                                                        openConfirmPaymentModal(
                                                                            offer.auction.uuid,
                                                                            offer.amount,
                                                                            "seller"
                                                                        )
                                                                    }
                                                                >
                                                                    Kuittaa maksetuksi
                                                                </Button>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title="Kuittaa maksu suoritetuksi ja ilmoita toteutunut kauppahinta myyntiprovision maksamista varten."
                                                                className="md:hidden"
                                                            >
                                                                <div className="text-lg">
                                                                    <QuestionCircleFilled className="text-blue-700 ml-2" />
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {auctionOffers.completedSells?.length > 0 && (
                            <div className="mb-5">
                                <h2 className="text-xl font-bold">Myydyt / vuokratut kohteet</h2>

                                <div className="text-sm grid md:grid-cols-3 grid-cols-1 gap-5">
                                    {auctionOffers.completedSells.map((offer) => (
                                        <div key={offer.auction.uuid} className="shadow-md p-3">
                                            <div className="text-base font-bold uppercase -m-3 bg-gray-200 p-3 mb-3">
                                                {AuctionTypeLabels[offer.auction.type]}
                                            </div>
                                            <div className="space-y-2">
                                                <div>
                                                    <div className="text-sm">Kohde</div>#{offer.auction.number}{" "}
                                                    {offer.auction.title}
                                                </div>
                                                <div>
                                                    <div className="text-sm">Tarjous annettu</div>
                                                    {offer.createdAt}
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <div className="text-sm">
                                                            {offer.auction.type === "auction"
                                                                ? "Korkein tarjous"
                                                                : "Tarjous"}
                                                        </div>
                                                        {offer.amount} &euro;
                                                    </div>
                                                </div>
                                                <div className="">
                                                    {offer.commission.status === "unpaid" ? (
                                                        <></>
                                                    ) : (
                                                        <div className="bg-green-600 text-white px-3 py-2 rounded text-center">
                                                            Myyntiprovisio maksettu {offer.commission.paidAt}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {auctionOffers.acceptedBids?.length > 0 && (
                            <div className="mb-5">
                                <h2 className="text-xl font-bold">Ostetut / vuokratut kohteet</h2>

                                <div className="text-sm grid md:grid-cols-3 grid-cols-1 gap-5">
                                    {auctionOffers.acceptedBids.map((offer) => (
                                        <div key={offer.auction.uuid} className="shadow-md p-3">
                                            <div className="text-base font-bold uppercase -m-3 bg-gray-200 p-3 mb-3">
                                                {AuctionTypeLabels[offer.auction.type]}
                                            </div>
                                            <div className="space-y-2">
                                                <div>
                                                    <div className="font-bold">Kohde</div>
                                                    <div>
                                                        #{offer.auction.number} {offer.auction.property.title}
                                                    </div>
                                                    <div className="text-sm">
                                                        vko {offer.auction.propertyWeek} /{" "}
                                                        {offer.auction.propertyWeekDates}
                                                    </div>
                                                    <div className="text-sm">
                                                        Huoneiston tunnus {offer.auction.roomNumber}
                                                    </div>
                                                    <div className="text-sm">{offer.auction.apartmentDescription}</div>
                                                    {offer.auction.files.length > 0 && (
                                                        <>
                                                            {offer.auction.files.map((file, index) => (
                                                                <div key={index} className="flex items-center">
                                                                    <DownloadOutlined className="mr-1 text-lg" />
                                                                    <div>
                                                                        <Link
                                                                            to="#"
                                                                            onClick={(e) =>
                                                                                handleFileDownload(
                                                                                    e,
                                                                                    file.id,
                                                                                    file.description
                                                                                )
                                                                            }
                                                                            className="underline text-blue-900"
                                                                        >
                                                                            {file.description}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                                <div>
                                                    <div className="font-bold">Tarjous annettu</div>
                                                    {offer.createdAt}
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <div className="font-bold">Tarjouksesi</div>
                                                        {offer.amount} €
                                                    </div>
                                                </div>
                                                <div className="font-bold">Myyjän tiedot</div>
                                                <div>
                                                    <div className="flex items-center text-lg">
                                                        <UserOutlined className="mr-1" /> {offer.seller.name}
                                                    </div>
                                                    <div className="flex items-center">
                                                        <MailOutlined className="mr-2" /> {offer.seller.email}
                                                    </div>
                                                    <div className="flex items-center">
                                                        <PhoneOutlined className="mr-2" /> {offer.seller.phone}
                                                    </div>
                                                </div>
                                                <div className="flex justify-between mt-5">
                                                    {offer.auction.paymentConfirmedAt === false ? (
                                                        <Tooltip title="Kun olet maksanut kauppasumman myyjälle, voit kuittata maksun suoritetuksi.">
                                                            <Button
                                                                type="primary"
                                                                onClick={() =>
                                                                    openConfirmPaymentModal(
                                                                        offer.auction.uuid,
                                                                        offer.amount,
                                                                        "buyer"
                                                                    )
                                                                }
                                                                className="w-full"
                                                            >
                                                                Kuittaa maksu suoritetuksi
                                                            </Button>
                                                        </Tooltip>
                                                    ) : (
                                                        <div className="bg-green-600 text-white px-3 py-2 rounded text-center w-full">
                                                            Maksu kuitattu {offer.auction.paymentConfirmedAt}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            <Modal
                title="Toteutunut kauppasumma"
                open={paymentConfirmModalOpen}
                onCancel={() => setPaymentConfirmModalOpen(false)}
                onOk={handleConfirmPayment}
                keyboard={true}
                footer={
                    <div className="flex md:flex-row flex-col gap-2">
                        <div className="flex-grow text-left">
                            <Button onClick={() => setPaymentConfirmModalOpen(false)}>Peruuta</Button>
                        </div>
                        <Button type="primary" onClick={handleConfirmPayment} loading={confirmingOffer}>
                            {confirmingOffer ? <Spin /> : "Vahvista"}
                        </Button>
                    </div>
                }
            >
                {paymentConfirmRole === "seller" && (
                    <>
                        <p>
                            Ilmoita toteutunut kauppasumma, jonka ostaja on maksanut. Myyntiprovisio on 7%
                            kauppasummasta (minimiprovisio 19,80€).
                        </p>
                        <p>
                            Vahvistamisen jälkeen saat laskun sähköpostiisi. Mikäli lasku ei saavu 24 tunnin kuluessa,
                            tarkista roskapostikansio. Tarvittaessa voit ottaa yhteyttä asiakaspalveluumme.
                        </p>
                        <p>Myyntiprovisio tulee maksaa 7 vrk kuluessa kaupan toteutumisesta.</p>
                    </>
                )}
                {paymentConfirmRole === "buyer" && (
                    <>
                        <p>
                            Ilmoita toteutunut ja maksamasi kauppasumma. Muistathan, että tarkoituksellinen virheellisen
                            tiedon ilmoittaminen voi johtaa käyttäjätilin sulkemiseen.
                        </p>
                    </>
                )}
                <Input
                    placeholder="Kauppasumma"
                    value={paymentConfirmAuctionAmount}
                    onPressEnter={handleConfirmPayment}
                    onChange={(e) => setPaymentConfirmAuctionAmount(e.target.value)}
                    addonAfter={"€"}
                />
            </Modal>
            {/* <Modal
                title="Maksunvälitys"
                open={paymentRedirectModalOpen}
                onCancel={() => setPaymentRedirectModalOpen(false)}
                onOk={handleConfirmPayment}
                keyboard={true}
                okText="Vahvista"
                cancelText="Peruuta"
            >
                Sinut siirretään maksupalveluun komission maksamista varten.
            </Modal> */}
        </>
    );
};

export default Profile;
