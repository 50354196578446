import React, { useState } from "react";
import { Form, Input, Button, Space, Radio, Checkbox, message, Modal } from "antd";

import api from "../../services/Api";

const CreateUserModal = ({ open, onClose }) => {
    const [form] = Form.useForm();
    const [showCompanyFields, setShowCompanyFields] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);

        try {
            await api.saveUser({ ...values, adminCreateUser: true });
            setError(false);
            message.success("Käyttäjä lisätty");
            onClose();
        } catch (error) {
            setError("Virhe tietojen tallentamisessa. " + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    const onUserTypeChange = (e) => {
        setShowCompanyFields(e.target.value === "company");
    };

    return (
        <Modal title="Lisää käyttäjä" open={open} onCancel={onClose} footer={null} width={700}>
            <Form
                form={form}
                name="profile"
                onFinish={onFinish}
                layout="horizontal"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 24 }}
                error={error}
            >
                <Form.Item name="adminCreateUser" initialValue={true} style={{ display: "none" }}>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item
                    label="Etunimi"
                    name="firstname"
                    rules={[{ required: true, message: "Etunimi on pakollinen." }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Sukunimi"
                    name="lastname"
                    rules={[{ required: true, message: "Sukunimi on pakollinen." }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Sähköposti"
                    name="email"
                    rules={[
                        { required: true, message: "Sähköpostiosoite on pakollinen." },
                        { type: "email", message: "Tarkista sähköpostiosoitteen muoto." },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Puhelinnumero"
                    name="phonenumber"
                    rules={[{ required: true, message: "Puhelinnumero on pakollinen." }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Käyttäjätyyppi"
                    name="type"
                    rules={[{ required: true, message: "Valitse käyttäjätilin tyyppi" }]}
                >
                    <Radio.Group onChange={onUserTypeChange} buttonStyle="solid">
                        <Radio.Button value="person">Yksityishenkilö</Radio.Button>
                        <Radio.Button value="company">Yritys</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                {showCompanyFields && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Form.Item
                            label="Yrityksen nimi"
                            name="companyName"
                            rules={[{ required: true, message: "Yrityksen nimi on pakollinen." }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Y-tunnus"
                            name="businessId"
                            rules={[{ required: true, message: "Yrityksen Y-tunnus on pakollinen." }]}
                        >
                            <Input />
                        </Form.Item>
                    </Space>
                )}

                <div className="mb-5">
                    Mikäli haluat ilmoituksia esim. päättyneistä huutokaupoista tai ylitetyistä tarjouksistasi salli
                    haluamasi viestintäkanavat.
                </div>

                <Form.Item label="Sähköposti" name="emailAllowed" valuePropName="checked">
                    <Checkbox />
                </Form.Item>

                <Form.Item label="Tekstiviesti" name="smsAllowed" valuePropName="checked">
                    <Checkbox />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" block loading={loading}>
                        Lisää käyttäjä
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateUserModal;
