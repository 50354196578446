import React, { useState, useEffect } from "react";
import { Modal, Button, Radio } from "antd";
import api from "../../services/Api";

const PublishModal = ({ open, onClose, auction, setAuction, setPublishedModalOpen }) => {
    const [publishing, setPublishing] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [promotions, setPromotions] = useState([]);
    const [promotionOptions, setPromotionOptions] = useState([]);
    const [selectedPromotion, setSelectedPromotion] = useState(0);
    const [showcases, setShowcases] = useState([]);
    const [showcaseOptions, setShowcaseOptions] = useState([]);
    const [selectedShowcase, setSelectedShowcase] = useState(0);

    const handleSelectPromotion = (id) => {
        const selectedPromo = promotions.find((promo) => promo.id === id);
        const selectedShow = showcases.find((show) => show.id === selectedShowcase);

        if (id === 0) {
            setTotalPrice(parseFloat(auction.price || 0) + parseFloat(selectedShow?.price || 0));
            setSelectedPromotion(0);

            return;
        }

        setSelectedPromotion(id);

        setTotalPrice(
            parseFloat(auction.price || 0) +
                parseFloat(selectedPromo?.price || 0) +
                parseFloat(selectedShow?.price || 0)
        );
    };

    const handleSelectShowcase = (id) => {
        const selectedShow = showcases.find((show) => show.id === id);
        const selectedPromo = promotions.find((promotion) => promotion.id === selectedPromotion);

        if (id === 0) {
            setTotalPrice(parseFloat(auction.price || 0) + parseFloat(selectedPromo?.price || 0));
            setSelectedShowcase(0);

            return;
        }

        setSelectedShowcase(id);

        setTotalPrice(
            parseFloat(auction.price || 0) +
                parseFloat(selectedShow?.price || 0) +
                parseFloat(selectedPromo?.price || 0)
        );
    };

    const fetchPrice = async () => {
        let response = await api.fetchPrice(auction.uuid, auction.discountCode);

        setAuction((prevAuction) => ({ ...prevAuction, price: response.price, originalPrice: response.originalPrice }));
        setTotalPrice(parseFloat(response.price || 0));

        response = await api.getProducts("promotion");
        setPromotions(response);

        response = await api.getProducts("showcase");
        setShowcases(response);
    };

    useEffect(() => {
        if (auction.uuid && auction.uuid !== "uusi") {
            fetchPrice();
        }
    }, [open]);

    const handlePublish = async () => {
        const promotionCode =
            selectedPromotion !== 0 ? promotions.find((promo) => promo.id === selectedPromotion).code : null;

        const showcaseCode =
            selectedShowcase !== 0 ? showcases.find((show) => show.id === selectedShowcase).code : null;

        try {
            setPublishing(true);

            let productCode = "";

            if (auction.auctionType === "auction") {
                productCode = "auction";
            } else if (auction.auctionType === "sale") {
                productCode = `sale-${auction.saleTime}`;
            } else if (auction.auctionType === "rent") {
                productCode = "rent";
            }

            if (totalPrice !== 0) {
                const payload = {
                    productCodes: [productCode, promotionCode, showcaseCode],
                    price: totalPrice.toFixed(2),
                    redirectUrl: `${window.location.origin}/oma-sivu/ilmoitukset/${auction.uuid}`,
                    auctionUuid: auction.uuid,
                    discountCode: auction.discountCode,
                };

                try {
                    const response = await api.createPayment(payload);
                    document.location.href = response.href;
                } catch (error) {
                    console.error("Error during payment processing: ", error);
                }
            } else {
                await api.saveAuction(auction.uuid, { ...auction, status: "published", paid: true });
                await api.publishAuction(auction.uuid);
                setPublishedModalOpen(true);
            }
        } catch (error) {
            console.error("Error publishing auction: ", error);
        } finally {
            setPublishing(false);
        }
    };

    useEffect(() => {
        const promotionOptions = promotions.map((promotion) => ({
            label: `${promotion.title} (${parseFloat(promotion.price).toFixed(2)}€)`,
            value: promotion.id,
        }));

        promotionOptions.unshift({ label: "Ei mainontaa - ilmoitus näkyy vain lomahuuto.fi -palvelussa", value: 0 });

        setPromotionOptions(promotionOptions);
    }, [promotions]);

    useEffect(() => {
        const showcaseOptions = showcases.map((showcase) => ({
            label: `${showcase.title} (${parseFloat(showcase.price).toFixed(2)}€)`,
            value: showcase.id,
        }));

        showcaseOptions.unshift({ label: "Ei näyteikkunaa", value: 0 });

        setShowcaseOptions(showcaseOptions);
    }, [showcases]);

    return (
        <Modal open={open} onCancel={onClose} footer={null} title="Julkaise ilmoitus" width={800}>
            <p>Haluatko varmistaa, että osakkeesi saa huomiota ja ostaja löytyy nopeasti?</p>
            <p>
                Ilman mainontaa ilmoituksesi näkyy vain Lomahuuto.fi-palvelussa, mikä rajoittaa huomattavasti
                potentiaalisten ostajien määrää. Mainos puolestaan tuo moninkertaisen näkyvyyden ja auttaa löytämään
                ostajan usein merkittävästi nopeammin.
            </p>
            <p>Valitse siis itsellesi sopiva mainospaketti ja tehosta myyntiäsi!</p>

            <Radio.Group
                value={selectedPromotion}
                onChange={(e) => handleSelectPromotion(e.target.value)}
                options={promotionOptions}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "10px",
                    marginBottom: "15px",
                }}
            />

            <p>
                Voit myös nostaa ilmoituksesi etusivun Kuukauden valinnat -näyteikkunaan ja varmistaa ilmoituksesi
                näkyvyyden.
            </p>

            <Radio.Group
                value={selectedShowcase}
                onChange={(e) => handleSelectShowcase(e.target.value)}
                options={showcaseOptions}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "10px",
                    marginBottom: "15px",
                }}
            />

            <p>Tämän ilmoituksen julkaiseminen maksaa</p>
            <p className="text-lg">
                <strong>{totalPrice.toFixed(2)}€</strong>
                {auction.originalPrice !== auction.price && (
                    <span className="ml-2">
                        (<span className="line-through text-red-600">{auction.originalPrice}€</span>)
                    </span>
                )}
            </p>

            <p className="mt-5">
                {auction.price !== "0.00" && (
                    <>
                        <p>Siirry maksamaan klikkaamalla "Jatka maksamaan" -painiketta.</p>
                        <p className="font-bold text-md border border-blue-600 p-2 rounded-md bg-blue-100 text-center">
                            Huom! Palaa maksamisen jälkeen takaisin Lomahuuto.fi -sivulle, jotta maksu kirjautuu
                            järjestelmään oikein.
                        </p>
                    </>
                )}
            </p>
            <div className="flex justify-between mt-5">
                <Button type="primary" className="bg-red-600 text-white" onClick={onClose}>
                    Peruuta
                </Button>
                <Button type="primary" className="bg-green-700 text-white" onClick={handlePublish}>
                    {auction.price === "0.00" ? "Julkaise ilmoitus" : "Siirry maksamaan"}
                </Button>
            </div>
        </Modal>
    );
};

export default PublishModal;
