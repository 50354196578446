import React, { useContext, useEffect, useState } from "react";
import api from "../../services/Api";
import { Popconfirm, Table, Input, Button } from "antd";
import { CheckCircleFilled, CloseCircleOutlined, LoginOutlined, ProfileOutlined, SearchOutlined } from "@ant-design/icons";
import UserModal from "../../components/modals/UserModal";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import CreateUserModal from "../../components/modals/CreateUserModal";

const Users = () => {
    const { user, hasRole } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.getUsers();
                setUsers(response);
                setFilteredUsers(response); // Initialize filtered users with full list
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();
    }, []);

    const handleSearch = (event) => {
        const term = event.target.value;
        setSearchTerm(term);

        // Filter users by name based on search term
        const filtered = users.filter((user) =>
            user.fullnameReverse.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredUsers(filtered);
    };

    const toggleEmailConfirmed = async (id) => {
        try {
            await api.userAction(id, "toggleEmailConfirmed");
            const updatedUsers = users.map((user) => {
                if (user.id === id) {
                    return { ...user, emailConfirmed: !user.emailConfirmed };
                }
                return user;
            });

            setUsers(updatedUsers);
            setFilteredUsers(updatedUsers.filter((user) =>
                user.fullnameReverse.toLowerCase().includes(searchTerm.toLowerCase())
            )); // Update filtered list based on search term
        } catch (error) {
            console.error("Error toggling email confirmed:", error);
        }
    };

    const toggleDeactivated = async (id) => {
        try {
            await api.userAction(id, "toggleDeactivated");
            const updatedUsers = users.map((user) => {
                if (user.id === id) {
                    return { ...user, deactivated: !user.deactivated };
                }
                return user;
            });

            setUsers(updatedUsers);
            setFilteredUsers(updatedUsers.filter((user) =>
                user.fullnameReverse.toLowerCase().includes(searchTerm.toLowerCase())
            )); // Update filtered list based on search term
        } catch (error) {
            console.error("Error toggling deactivated:", error);
        }
    };

    const openUserModal = (user) => {
        setSelectedUser(user);
        setUserModalOpen(true);
    };

    const columns = [
        {
            title: "Nimi",
            dataIndex: "fullnameReverse",
            key: "fullname",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.fullnameReverse.localeCompare(b.fullnameReverse),
        },
        {
            title: "Sähköposti",
            dataIndex: "email",
            key: "email",
            render: (email) => (
                <a href={`mailto:${email}`} className="text-blue-700 underline">
                    {email}
                </a>
            ),
        },
        {
            title: "Puhelin",
            dataIndex: "phonenumber",
            key: "phonenumber",
        },
        {
            title: "Julkaistu",
            align: "center",
            sorter: (a, b) => (a.auctionCount?.published || 0) - (b.auctionCount?.published || 0),
            sortDirections: ["descend"],
            render: (record) => (
                <div className="flex items-center justify-center">
                    {record.auctionCount?.published && (
                        <span className="size-5 flex items-center justify-center rounded bg-green-300">
                            {record.auctionCount?.published}
                        </span>
                    )}
                </div>
            ),
        },
        {
            title: "Luonnos",
            align: "center",
            sorter: (a, b) => (a.auctionCount?.draft || 0) - (b.auctionCount?.draft || 0),
            sortDirections: ["descend"],
            render: (record) => (
                <div className="flex items-center justify-center">
                    {record.auctionCount?.draft && (
                        <span className="size-5 flex items-center justify-center rounded bg-amber-300">
                            {record.auctionCount?.draft}
                        </span>
                    )}
                </div>
            ),
        },
        {
            title: "Vahvistettu",
            dataIndex: "emailConfirmed",
            key: "emailConfirmed",
            align: "center",
            width: 50,
            render: (confirmed, record) =>
                confirmed ? (
                    <CheckCircleFilled
                        className="text-green-600 text-lg cursor-pointer"
                        onClick={() => toggleEmailConfirmed(record.id)}
                    />
                ) : (
                    <CloseCircleOutlined
                        className="text-gray-600 text-lg cursor-pointer"
                        onClick={() => toggleEmailConfirmed(record.id)}
                    />
                ),
        },
        {
            title: "Estetty",
            dataIndex: "deactivated",
            key: "deactivated",
            align: "center",
            width: 50,
            render: (deactivated, record) =>
                deactivated ? (
                    <CheckCircleFilled
                        className="text-red-600 text-lg cursor-pointer"
                        onClick={() => toggleDeactivated(record.id)}
                    />
                ) : (
                    <Popconfirm
                        title="Haluatko varmasti estää käyttäjän?"
                        onConfirm={() => toggleDeactivated(record.id)}
                    >
                        <CloseCircleOutlined className="text-gray-600 text-lg cursor-pointer" />
                    </Popconfirm>
                ),
        },
        {
            title: "Avaa",
            key: "open",
            align: "center",
            width: 50,
            render: (record) => <ProfileOutlined className="text-lg cursor-pointer" onClick={() => openUserModal(record)} />,
        },
        {
            title: "",
            key: "impersonate",
            align: "center",
            width: 50,
            render: (record) => (
                hasRole(user.roles, "ROLE_GOD") && (
                    <LoginOutlined 
                        className="text-lg cursor-pointer"
                        onClick={handleImpersonate(record.id)}
                    />
                )
            ),
        }
    ];

    const handleImpersonate = (id) => async () => {
        try {
            const response = await api.userAction(id, "impersonate");

            if (response.token) {
                localStorage.setItem('token', response.token);
                navigate("/oma-sivu");
            }
        } catch (error) {
            console.error("Error impersonating user:", error);
        }
    }

    const openCreateUserModal = () => {
        setCreateUserModalOpen(true);
    };

    return (
        <div className="container max-w-screen-2xl m-auto text-lg">
            <h1 className="text-2xl mb-5">Käyttäjät</h1>

            <Input
                placeholder="Hae käyttäjän nimellä"
                prefix={<SearchOutlined />}
                value={searchTerm}
                onChange={handleSearch}
                className="mb-5 w-full max-w-md"
            />

            <div className="text-lg mb-5">Käyttäjiä: {filteredUsers.length}</div>

            <Button onClick={() => openCreateUserModal()} type="primary">Lisää käyttäjä</Button>

            <Table dataSource={filteredUsers} columns={columns} rowKey="id" size="small" className="mt-5" />

            <UserModal open={userModalOpen} onClose={() => setUserModalOpen(false)} user={selectedUser} />
            <CreateUserModal open={createUserModalOpen} onClose={() => setCreateUserModalOpen(false)} />
        </div>
    );
};

export default Users;
