import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../services/Api";

export const AuthContext = createContext();

const ROLE_HIERARCHY = {
    ROLE_GOD: ["ROLE_SUPERADMIN", "ROLE_ADMIN", "ROLE_SEO", "ROLE_USER"],
    ROLE_SUPERADMIN: ["ROLE_ADMIN", "ROLE_SEO", "ROLE_USER"],
    ROLE_ADMIN: ["ROLE_USER", "ROLE_SEO"],
    ROLE_SEO: ["ROLE_USER"],
    ROLE_USER: [],
};

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        isAuthenticated: !!localStorage.getItem("token"),
        user: null,
    });
    const [referer, setReferer] = useState("/");
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const verifySession = async () => {
            if (authState.isAuthenticated) {
                try {
                    const data = await api.checkSession();
                    setAuthState({
                        isAuthenticated: true,
                        user: data.user,
                    });
                } catch (error) {
                    setAuthState({ isAuthenticated: false, user: null });
                    localStorage.removeItem("token");
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        verifySession();
    }, [authState.isAuthenticated]);

    const login = (token, user, _remember_me) => {
        localStorage.setItem("token", token);
        setAuthState({ isAuthenticated: true, user });
        
        if (referer !== '/') {
            navigate(referer);
        }
    };

    const logout = () => {
        localStorage.removeItem("token");
        setAuthState({ isAuthenticated: false, user: null });
        navigate("/");
    };

    const hasRole = (userRoles, requiredRole) => {
        const allRoles = new Set(userRoles);
        userRoles.forEach((role) => {
            if (ROLE_HIERARCHY[role]) {
                ROLE_HIERARCHY[role].forEach((subRole) => allRoles.add(subRole));
            }
        });
        return allRoles.has(requiredRole);
    };

    return (
        <AuthContext.Provider value={{ ...authState, login, logout, setReferer, hasRole, loading }}>
            {children}
        </AuthContext.Provider>
    );
};
