import React, { useState, useEffect } from "react";

import api from "../../services/Api";
import { Spin, Table, Button } from "antd";
import { Link, useParams } from "react-router-dom";
import AuctionList from "./AuctionList";
import { Helmet } from "react-helmet-async";
import { AdvancedMarker, APIProvider, Map, useMapsLibrary } from "@vis.gl/react-google-maps";

const AuctionCategoryPage = ({ auctionType }) => {
    const { areaslug } = useParams();
    const [filters, setFilters] = useState({ types: [auctionType], areaslugs: [areaslug] });
    const [areas, setAreas] = useState([]);
    const [loadingAreas, setLoadingAreas] = useState(true);
    const [area, setArea] = useState({});
    const [center, setCenter] = useState({ lat: 37.7749, lng: -122.4194 });
    const geocoding = useMapsLibrary("geocoding"); // Load the Geocoding library

    const position = { lat: 53.54992, lng: 10.00678 };

    const limit = 10;

    const auctionTypeTranslations = [
        { type: "sale", translation: "Myydään lomaosake" },
        { type: "rent", translation: "Vuokrataan lomaosake" },
        { type: "auction", translation: "Huutokaupat" },
    ];

    const auctionTypeSlugs = [
        { type: "sale", slug: "myydaan-lomaosake" },
        { type: "rent", slug: "vuokrataan-lomaosake" },
        { type: "auction", slug: "huutokaupat" },
    ];

    useEffect(() => {
        setFilters({ types: [auctionType], areaslugs: [areaslug] });
    }, [areaslug, auctionType]);

    useEffect(() => {
        const fetchAreas = async () => {
            setLoadingAreas(true);

            try {
                const response = await api.getAvailableAreasAuctions();
                setAreas(response);
            } catch (error) {
                console.error("Error fetching areas:", error);
            } finally {
                setLoadingAreas(false);
            }
        };

        fetchAreas();
    }, []);

    useEffect(() => {
        const fetchArea = async () => {
            try {
                const response = await api.getAreaBySlug(areaslug);
                setArea(response);
            } catch (error) {
                console.error("Error fetching area:", error);
            }
        };

        fetchArea();
    }, [areaslug]);

    useEffect(() => {
        const geocodeAddress = async (address) => {
            if (!geocoding) return;

            const geocoder = new geocoding.Geocoder();
            
            geocoder.geocode({ address }, (results, status) => {
                if (status === "OK" && results[0]?.geometry?.location) {
                    setCenter({
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng(),
                    });
                } else {
                    console.error("Geocode failed: ", status);
                }
            });
        };

        if (area.name) {
            geocodeAddress(area.name);
        }
    }, [geocoding, area]);


    return (
        <>
            {!loadingAreas && (
                <Helmet>
                    <title>
                        {auctionTypeTranslations.find((t) => t.type === auctionType).translation} -{" "}
                        {areas.find((a) => a.slug === areaslug).area} - Lomahuuto.fi
                    </title>
                    <meta
                        name="title"
                        content={
                            auctionTypeTranslations.find((t) => t.type === auctionType).translation +
                            " - " +
                            areas.find((a) => a.slug === areaslug).area
                        }
                    />
                    {/* <meta name="description" content={auctionDescription} /> */}
                    <meta
                        property="og:title"
                        content={
                            auctionTypeTranslations.find((t) => t.type === auctionType).translation +
                            " - " +
                            areas.find((a) => a.slug === areaslug).area
                        }
                    />
                    {/* <meta property="og:description" content={auctionDescription} /> */}
                    {/* <meta property="og:image" content={auction.images[0]?.urls.medium} /> */}
                </Helmet>
            )}
            <div className="container max-w-screen-2xl m-auto text-lg md:p-5 md:px-0">
                {!loadingAreas && (
                    <div className="flex items-center text-gray-500 text-sm space-x-2 mb-5">
                        <Link to="/">Etusivu</Link>
                        <span className="mx-2">/</span>
                        <Link to={`/${auctionTypeSlugs.find((t) => t.type === auctionType).slug}`}>
                            {auctionTypeTranslations.find((t) => t.type === auctionType).translation}
                        </Link>
                        <span className="mx-2">/</span>
                        <Link to={`/${auctionTypeSlugs.find((t) => t.type === auctionType).slug}/${areaslug}`}>
                            {areas.find((a) => a.slug === areaslug).area}
                        </Link>
                    </div>
                )}
                <div className=" flex">
                    <div className="w-1/6">
                        <h2>Alueet</h2>
                        {loadingAreas ? (
                            <Spin />
                        ) : (
                            <ul className="list-none p-0 m-0 text-sm">
                                {areas.map((area) => (
                                    <li key={area.slug}>
                                        <Link
                                            className={`${
                                                areaslug === area.slug ? "font-bold text-primary-200" : ""
                                            } hover:text-blue-500`}
                                            to={`/${auctionTypeSlugs.find((t) => t.type === auctionType).slug}/${
                                                area.slug
                                            }`}
                                        >
                                            {area.area}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="flex-1">
                        {!loadingAreas && (
                            <>
                                <h2>
                                    {auctionTypeTranslations.find((t) => t.type === auctionType).translation} -{" "}
                                    {areas.find((a) => a.slug === areaslug).area}
                                </h2>
                                {area.description && <div dangerouslySetInnerHTML={{ __html: area.description }} />}
                                <AuctionList filters={filters} />

                                <APIProvider apiKey={"AIzaSyCsgQvonrmzuhvpd2vQ4oq04vsXrXagTIU"}>
                                    <Map
                                        defaultCenter={center}
                                        defaultZoom={10}
                                        mapId="DEMO_MAP_ID"
                                        colorScheme="FOLLOW_SYSTEM"
                                        style={{
                                            marginTop: "20px",
                                            width: "100%",
                                            height: "400px",
                                            borderRadius: "10px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <AdvancedMarker position={center} />
                                    </Map>
                                </APIProvider>

                                {area.seoDescription && (
                                    <div className="mt-10" dangerouslySetInnerHTML={{ __html: area.seoDescription }} />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuctionCategoryPage;
