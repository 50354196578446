import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Spin } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import api from "../../services/Api";

const PaymentReturn = () => {
    const { result } = useParams();
    const [loading, setLoading] = useState(true);
    const [auction, setAuction] = useState(null);
    const [payment, setPayment] = useState(null);

    const params = new URLSearchParams(window.location.search);

    const sales = ["auction", "sale-6", "sale-12", "rent"];
    const showcases = ["showcase-1", "showcase-2", "showcase-3"];

    if (!params.has("checkout-transaction-id")) {
        document.location.href = "/";
    }

    const confirmPayment = async () => {
        setLoading(true);

        try {
            const response = await api.confirmPayment(
                params.get("checkout-stamp"),
                params.get("checkout-transaction-id")
            );

            setPayment(response);
            setAuction(response.auction);
        } catch (error) {
            console.error("Error during payment processing: ", error);
        } finally {
            setLoading(false);
        }
    };

    const hasConfirmed = useRef(false);

    useEffect(() => {
        if (hasConfirmed.current) return;
        hasConfirmed.current = true;
    
        confirmPayment();
    }, []);    

    return (
        <div className="flex flex-col justify-center w-full items-center">
            {result === "cancel" && (
                <>
                    {loading ? (
                        <div className="text-3xl w-full flex justify-center flex-col items-center">
                            <Spin className="mr-2 mb-5" size="large" />
                            <div>Odota hetki...</div>
                        </div>
                    ) : (
                        <div className="text-3xl w-full flex justify-center flex-col items-center">
                            <CloseCircleOutlined className="text-7xl mb-10 text-red-600" />

                            <h2 className="text-2xl">Maksutapahtuma peruutettu</h2>
                            <p className="text-xl">{payment.message}</p>

                            {(sales.includes(payment.product?.code) || payment.product?.code === "service-1") &&
                                auction && (
                                    <p>
                                        <Link
                                            to={`/oma-sivu/ilmoitukset/` + auction.uuid}
                                            className="text-xl underline text-blue-700"
                                        >
                                            Siirry takaisin ilmoituksen muokkaukseen
                                        </Link>
                                    </p>
                                )}

                            {(payment.product?.code === "service-2" || payment.product?.code === "service-3") &&
                                auction && (
                                    <p>
                                        <Link
                                            to={`/oma-sivu/sopimus/` + auction.uuid}
                                            className="text-xl underline text-blue-700"
                                        >
                                            Siirry takaisin sopimukseen
                                        </Link>
                                    </p>
                                )}

                            {showcases.includes(payment.product?.code) && auction && (
                                <p>
                                    <Link to={`/`} className="text-xl underline text-blue-700">
                                        Siirry etusivulle
                                    </Link>
                                </p>
                            )}
                        </div>
                    )}
                </>
            )}

            {result === "success" && (
                <>
                    {loading ? (
                        <div className="text-3xl w-full flex justify-center flex-col items-center">
                            <Spin className="mr-2 mb-5" size="large" />
                            <div>Vahvistetaan maksua...</div>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center justify-center">
                            {payment.status === "ok" ? (
                                <>
                                    <CheckCircleOutlined className="text-7xl mb-10 text-green-600" />

                                    <h2 className="text-2xl">Maksu onnistui</h2>
                                    <p className="text-xl">{payment.message}</p>

                                    {sales.includes(payment.product?.code) && auction && (
                                        <p>
                                            <Link
                                                to={`/kohde/` + auction.number}
                                                className="text-xl underline text-blue-700"
                                            >
                                                Katso ilmoituksesi täällä
                                            </Link>
                                        </p>
                                    )}

                                    {showcases.includes(payment.product?.code) && auction && (
                                        <p>
                                            <Link to={`/`} className="text-xl underline text-blue-700">
                                                Siirry etusivulle
                                            </Link>
                                        </p>
                                    )}
                                </>
                            ) : (
                                <>
                                    <CloseCircleOutlined className="text-7xl mb-10 text-red-600" />

                                    <h2 className="text-2xl">
                                        {result === "cancel" ? "Maksutapahtuma peruutettu" : "Maksu epäonnistui"}
                                    </h2>
                                    <p className="text-xl">
                                        Yritä uudelleen tai ota yhteyttä{" "}
                                        <Link to={`/yhteystiedot`} className="text-xl underline text-blue-700">
                                            asiakaspalveluun
                                        </Link>
                                        .
                                    </p>

                                    {(sales.includes(payment.product?.code) || payment.product?.code === "service-1") &&
                                        auction && (
                                            <p>
                                                <Link
                                                    to={`/oma-sivu/ilmoitukset/` + auction.uuid}
                                                    className="text-xl underline text-blue-700"
                                                >
                                                    Siirry takaisin ilmoituksen muokkaukseen
                                                </Link>
                                            </p>
                                        )}

                                    {(payment.product?.code === "service-2" || payment.product?.code === "service-3") &&
                                        auction && (
                                            <p>
                                                <Link
                                                    to={`/oma-sivu/sopimus/` + auction.uuid}
                                                    className="text-xl underline text-blue-700"
                                                >
                                                    Siirry takaisin sopimukseen
                                                </Link>
                                            </p>
                                        )}

                                    {showcases.includes(payment.product?.code) && auction && (
                                        <p>
                                            <Link to={`/`} className="text-xl underline text-blue-700">
                                                Siirry etusivulle
                                            </Link>
                                        </p>
                                    )}
                                </>
                            )}

                            <p>
                                <Link to={`/oma-sivu`} className="text-xl underline text-blue-700 mt-2">
                                    Siirry omalle sivulle
                                </Link>
                            </p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default PaymentReturn;
