import React, { useState, useEffect, useContext } from "react";
import { Form, Button, message, Spin, Input, Select, Popconfirm, Table } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import api from "../../services/Api";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";

const Settings = () => {
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user, hasRole } = useContext(AuthContext);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            const response = await api.getSettings();
            setSettings(response);
        } catch (error) {
            message.error("Error fetching settings");
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: "Nimi",
            dataIndex: "label",
            key: "label",
        },
        {
            title: "Arvo",
            dataIndex: "value",
            key: "value",
            render: (value, record) => {
                if (record.type === "text") {
                    return value.substring(0, 50) + "...";
                }
                return value;
            },
        },
        {
            title: "",
            key: "actions",
            className: "text-right",
            render: (text, record) => (
                <>
                    <Link to={`/hallinta/asetukset/${record.id}`} className="mr-2">
                        <Button type="primary">Muokkaa</Button>
                    </Link>
                </>
            ),
        },
    ];

    const handleDeleteSetting = async (id) => {
        try {
            await api.deleteSetting(id);
            message.success("Asetus poistettu onnistuneesti");
            fetchSettings();
        } catch (error) {
            message.error("Virhe poistamisessa");
        }
    };

    return (
        <div className="container max-w-screen-2xl m-auto text-lg">
            <h1 className="text-2xl mb-5">Asetukset</h1>
            {loading ? (
                <Spin />
            ) : (
                <>
                    <Table
                        size="small"
                        className="table table-striped w-full"
                        dataSource={settings}
                        columns={columns}
                        pagination={false}
                    />
                </>
            )}
        </div>
    );
};

export default Settings;
