import React, { useState, useEffect, useContext } from "react";
import { Form, Button, message, Spin, Input, Select, Popconfirm, Table, InputNumber } from "antd";
import api from "../../services/Api";
import { AuthContext } from "../../context/AuthContext";
import { Link, useParams } from "react-router-dom";
import CKEditorWrapper from "../../components/common/CKEditorWrapper";

const SettingsEdit = () => {
    const [loading, setLoading] = useState(true);
    const { user, hasRole } = useContext(AuthContext);
    const [saving, setSaving] = useState(false);
    const [setting, setSetting] = useState(null);
    const [form] = Form.useForm();
    const [errors, setErrors] = useState(null);
    const settingId = useParams().settingId;

    useEffect(() => {
        if (!hasRole(user.roles, "ROLE_ADMIN")) {
            return null;
        }

        const fetchSettingContent = () =>
            api
                .getSettingById(settingId)
                .then((response) => {
                    setSetting(response);
                    form.setFieldsValue(response);
                })
                .catch((error) => {
                    console.error("Error fetching setting:", error);
                })
                .finally(() => {
                    setLoading(false);
                });

        if (settingId) {
            fetchSettingContent();
        }
    }, [settingId]);

    const handleSave = (values) => {
        setSaving(true);
        console.log(values);

        api.saveSetting(settingId, values)
            .then(() => {
                message.success("Asetus tallennettu onnistuneesti");
            })
            .catch((error) => {
                console.error("Error saving setting:", error);
                setErrors(error);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const handleChange = (key, value) => {
        form.setFieldsValue({ [key]: value });
    };

    return (
        <>
            <Link to="/hallinta/asetukset" className="mb-2 block">
                <Button type="primary">Takaisin</Button>
            </Link>

            <h3>Muokkaa asetusta</h3>

            {loading ? (
                <Spin />
            ) : (
                <>
                    <Form form={form} onFinish={handleSave} layout="vertical">
                        <Form.Item
                            label="Tunniste"
                            name="name"
                            rules={[{ required: true, message: "Tunniste vaaditaan" }]}
                        >
                            <Input readOnly />
                        </Form.Item>

                        <Form.Item label="Nimi" name="label" rules={[{ required: true, message: "Nimi vaaditaan" }]}>
                            <Input readOnly />
                        </Form.Item>

                        <Form.Item label="Arvo" name="value" rules={[{ required: true, message: "Arvo vaaditaan" }]}>
                            {setting.type === "text" ? (
                                <CKEditorWrapper
                                    content={setting.value}
                                    setContent={(content) => handleChange("value", content)}
                                />
                            ) : setting.type === "number" ? (
                                <InputNumber
                                    type="number"
                                    value={setting.value}
                                    onChange={(value) => handleChange("value", value)}
                                />
                            ) : setting.type === "string" ? (
                                <Input value={setting.value} onChange={(e) => handleChange("value", e.target.value)} />
                            ) : null}
                        </Form.Item>

                        <Form.Item className="mt-5">
                            <Button loading={saving} type="primary" htmlType="submit">
                                Tallenna
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            )}
        </>
    );
};

export default SettingsEdit;
