import React, { useEffect, useState } from "react";
import { Form, Input, Button, Spin, message, Tooltip } from "antd";
import api from "../../services/Api";
import { Link, useParams } from "react-router-dom";
import CKEditorWrapper from "../../components/common/CKEditorWrapper";
import TextArea from "antd/es/input/TextArea";
import { DoubleLeftOutlined, QuestionCircleFilled } from "@ant-design/icons";

const AreasEdit = () => {
    const [loading, setLoading] = useState(false);
    const [area, setArea] = useState(null);
    const [editing, setEditing] = useState(false);
    const areaId = parseInt(useParams().areaId);
    const [form] = Form.useForm();

    const handleEditorChange = (content) => {
        form.setFieldsValue({ description: content });
    };

    const handleSeoDescriptionChange = (content) => {
        form.setFieldsValue({ seoDescription: content });
    };

    useEffect(() => {
        const fetchArea = async () => {
            try {
                setLoading(true);
                const response = await api.getArea(areaId);
                setArea(response);
                setEditing(true);
                form.setFieldsValue(response);
            } catch (error) {
                message.error("Virhe ladatessa aluetta, yritä uudelleen.");
            } finally {
                setLoading(false);
            }
        };

        if (areaId) fetchArea();
    }, [areaId, form]);

    const handleSaveArea = async (values) => {
        try {
            if (editing) {
                await api.updateArea(areaId, values);
            } else {
                await api.createArea(values);
            }
            message.success("Alue tallennettu onnistuneesti.");
        } catch (error) {
            message.error("Virhe tallennettaessa aluetta, yritä uudelleen.");
        }
    };

    const handleFinish = (values) => {
        handleSaveArea(values);
    };

    return (
        <div className={"container m-auto max-w-screen-2xl md:px-0 text-base"}>
            <Link to="/hallinta/alueet">
                <Button type="primary" className="flex items-center mb-5">
                    Takaisin alueisiin
                </Button>
            </Link>
            <h1>{editing ? "Muokkaa aluetta" : "Lisää uusi alue"}</h1>
            {area ? (
                <Form form={form} onFinish={handleFinish} layout="vertical">
                    <div className="grid grid-cols-2 gap-4">
                        <Form.Item
                            label="Nimi"
                            name="name"
                            rules={[{ required: true, message: "Alueen nimi vaaditaan" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Slug"
                            name="slug"
                            rules={[{ required: true, message: "Alueen slug vaaditaan" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label="Kuvaus" name="description" className="col-span-2">
                            <CKEditorWrapper
                                content={area?.description}
                                setContent={handleEditorChange}
                                height="200px"
                            />
                        </Form.Item>

                        <div className="text-lg mb-5 col-span-2">SEO-tiedot</div>

                        <Form.Item label="Otsikko" name="seoTitle">
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={
                                <>
                                    Avainsanat{" "}
                                    <Tooltip title="Anna avainsanat pilkuilla eroteltuna">
                                        <div className="text-lg">
                                            <QuestionCircleFilled className="text-blue-700 ml-2" />
                                        </div>
                                    </Tooltip>
                                </>
                            }
                            name="seoKeywords"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item label="Kuvaus" name="seoDescription">
                            <CKEditorWrapper
                                content={area?.seoDescription}
                                setContent={handleSeoDescriptionChange}
                                height="200px"
                            />
                        </Form.Item>

                        <Form.Item label="Schema" name="seoSchema">
                            <TextArea style={{ height: "240px" }} />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={loading}>
                            {loading ? <Spin /> : "Tallenna"}
                        </Button>
                    </Form.Item>
                </Form>
            ) : (
                <Spin />
            )}
        </div>
    );
};

export default AreasEdit;
