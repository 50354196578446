import React, { useState, useEffect } from "react";
import { Table, message, Tooltip, Select } from "antd";
import api from "../../services/Api";
import { Link } from "react-router-dom";
import { FileTextOutlined } from "@ant-design/icons";

const { Option } = Select;

const statusColors = {
    LUOTU: "bg-yellow-500",
    VALMIS: "bg-green-500",
    PERUUTETTU: "bg-black",
    EPÄONNISTUNUT: "bg-red-500",
};

const Payments = () => {
    const [payments, setPayments] = useState([]);
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("VALMIS");

    useEffect(() => {
        const fetchPayments = async () => {
            setLoading(true);
            try {
                const response = await api.getPayments();
                setPayments(response);
                filterPayments(response, "VALMIS");
            } catch (error) {
                console.error("Error fetching payments:", error);
                message.error("Failed to fetch payments.");
            } finally {
                setLoading(false);
            }
        };

        fetchPayments();
    }, []);

    const filterPayments = (allPayments, status) => {
        if (status === "ALL") {
            setFilteredPayments(allPayments);
        } else {
            setFilteredPayments(allPayments.filter((payment) => payment.status === status));
        }
    };

    const handleStatusChange = (value) => {
        setSelectedStatus(value);
        filterPayments(payments, value);
    };

    const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Pvm",
            dataIndex: "createdAt",
            key: "createdAt",
            defaultSortOrder: "descend",
            sorter: (a, b) => new Date(a.createdAt.date) - new Date(b.createdAt.date),
            render: (text) => (text?.date ? new Date(text.date).toLocaleDateString("fi-FI") + ' klo ' + new Date(text.date).toLocaleTimeString("fi-FI", timeOptions) : ""),
        },
        {
            title: "Asiakas",
            dataIndex: "customer",
            key: "customer",
            render: (text) => <>{text ? text.fullName : ""}</>,
        },
        {
            title: "Tila",
            dataIndex: "status",
            key: "status",
            render: (text) => (
                <span className={`text-xs text-white px-2 py-1 rounded ${statusColors[text] || "bg-gray-500"}`}>
                    {text}
                </span>
            ),
        },
        {
            title: "Tuotteet",
            dataIndex: "products",
            key: "products",
            render: (products) =>
                products.map((product) => (
                    <div className="border-b border-gray-200 py-1 last:border-0" key={product.id}>
                        <Tooltip className="flex justify-between" title={product.title}>
                            <span className="text-xs uppercase">{product.code}</span>
                            <span className="text-xs">{parseFloat(product.price).toFixed(2)}€</span>
                        </Tooltip>
                    </div>
                )),
        },
        {
            title: "Ilmoitus",
            dataIndex: "auction",
            key: "auction",
            render: (text) =>
                text && text.uuid ? (
                    <Link className="text-blue-700" to={`/oma-sivu/ilmoitukset/${text.uuid}`}>
                        <FileTextOutlined /> {text.number}
                    </Link>
                ) : (
                    ""
                ),
        },
        {
            title: <div className="text-right">Summa</div>,
            dataIndex: "amount",
            key: "amount",
            render: (text) => <div className="text-md text-right">{parseFloat(text).toFixed(2)}€</div>,
        },
    ];

    return (
        <div>
            <h1>Maksusuorituset</h1>
            <div className="mb-4">
                <Select value={selectedStatus} onChange={handleStatusChange} style={{ width: 200 }}>
                    <Option value="ALL">Kaikki</Option>
                    <Option value="LUOTU">LUOTU</Option>
                    <Option value="VALMIS">VALMIS</Option>
                    <Option value="PERUUTETTU">PERUUTETTU</Option>
                    <Option value="EPÄONNISTUNUT">EPÄONNISTUNUT</Option>
                </Select>
            </div>
            <Table columns={columns} dataSource={filteredPayments} loading={loading} rowKey="id" />
        </div>
    );
};

export default Payments;
