import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputNumber, message, Modal, Spin } from "antd";

import { AuthContext } from "../../../context/AuthContext";

import api from "../../../services/Api";

const AuctionBidInfo = ({ auction, setAuction }) => {
    const { isAuthenticated } = useContext(AuthContext);

    const [userHasBid, setUserHasBid] = useState(false);
    const [rejectedBid, setRejectedBid] = useState(false);
    const [bids, setBids] = useState(false);
    const [bidding, setBidding] = useState(false);
    const [bidAmount, setBidAmount] = useState(null);
    const [bidFormError, setBidFormError] = useState("");
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

    // const audioRef = useRef(new Audio("../../assets/sounds/bid-chime.wav"));
    // const previousBidderId = useRef(null);

    const dateOptions = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "Europe/Helsinki",
    };

    const fetchBids = async () => {
        if (auction.type === "rent") {
            return;
        }

        try {
            const response = await api.getAuctionBids(auction.number);
            const fetchedBids = response;
            const highestBid = Math.max(...fetchedBids.bids.map((bid) => bid.amount), 0);

            if (auction.type === "sale") {
                setUserHasBid(fetchedBids.bidders ? true : false);

                if (fetchedBids.bids[0].status === "rejected") {
                    setRejectedBid(true);
                } else {
                    setRejectedBid(false);
                }
            }

            if (bidAmount <= highestBid) {
                setBidAmount(highestBid + auction.minBid);
            }

            setAuction((prevAuction) => ({
                ...prevAuction,
                ends: fetchedBids.endsAt,
                bids: fetchedBids,
                userHasHighestBid: fetchedBids.userHasHighestBid,
                highestBid: highestBid > prevAuction.highestBid ? highestBid : prevAuction.highestBid,
            }));
        } catch (error) {
            console.error("Error fetching bids:", error);
        }
    };

    useEffect(() => {
        fetchBids();

        const interval = setInterval(fetchBids, 10000);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, [auction.number]);

    useEffect(() => {
        if (auction.type === "auction") {
            if (auction.highestBid) {
                setBidAmount(auction.highestBid + auction.minBid);
            } else {
                setBidAmount(auction.startPrice + auction.minBid);
            }
        }

        if (auction.type === "sale") {
            setBidAmount(auction.startPrice);
        }
    }, []);

    // useEffect(() => {
    //     if (auction && bids?.length > 0) {
    //         const lastBid = bids[0];

    //         if (lastBid.user && previousBidderId.current !== lastBid.user && lastBid.user !== user) {
    //             previousBidderId.current = lastBid.user;

    //             if (soundEnabled) {
    //                 audioRef.current.load(); // Ensure the audio is loaded before playing
    //                 audioRef.current.play().catch((error) => {
    //                     console.error("Error playing audio:", error);
    //                 });
    //             }
    //         }
    //     }
    // }, [auction, soundEnabled, user]);

    const handleBidSubmit = () => {
        if (!bidAmount || bidAmount <= 0) {
            message.error("Anna tarjoussumma");
            return;
        }

        // Show the confirmation modal
        setIsConfirmModalVisible(true);
    };

    const handleConfirmBid = async () => {
        setIsConfirmModalVisible(false);
        setBidding(true);
        setBidFormError("");

        try {
            const response = await api.submitBid(auction.number, { amount: bidAmount });
            setBidAmount(response.amount + auction.minBid);
            fetchBids();
        } catch (error) {
            setBidFormError(error.response.data.message);
        } finally {
            setBidding(false);
        }
    };

    const confirmOffer = async () => {
        const { confirm } = Modal;

        confirm({
            title: "Vahvista",
            content: (
                <>
                    {auction.type === "sale" && (
                        <>
                            <div>Olet tekemässä tarjouksen kohteesta {auction.property.title}:</div>
                            <div>
                                Tarjouksen summa <strong>{bidAmount}&euro;</strong>
                            </div>
                        </>
                    )}
                    {auction.type === "rent" && (
                        <>
                            <di>
                                Olet vuokraamassa kohdetta {auction.property.title}
                                <br />
                                ajalle {auction.propertyWeekDates}.
                            </di>
                        </>
                    )}
                </>
            ),
            okText: auction.type === "sale" ? "Tee tarjous" : "Vahvista",
            cancelText: "Peruuta",
            async onOk() {
                setBidding(true);
                setBidFormError("");

                try {
                    const response = await api.submitOffer(auction.number, { amount: bidAmount });
                    setBids([response, ...bids]);
                    setUserHasBid(true);
                } catch (error) {
                    setBidFormError(error.response?.data.message);
                } finally {
                    setBidding(false);
                }
            },
        });
    };

    return (
        <>
            {auction.type === "auction" && (
                <>
                    <div className="bg-blue-500 rounded p-2 text-white text-sm text-center mb-5">
                        {auction.confirmedSale ? (
                            <>
                                <div className="font-bold text-xl mb-2">Varmat kaupat!</div>
                                <p>Myyjä on sitoutunut myymään kohteen korkeimman tarjouksen tehneelle.</p>
                            </>
                        ) : (
                            "Myyjä pidättää oikeuden hyväksyä tai hylätä korkeimman tarjouksen."
                        )}
                    </div>

                    {auction.status === "published" && (
                        <>
                            <>
                                {auction.bids ? (
                                    <>
                                        {isAuthenticated && !auction.isUserAuction && (
                                            <>
                                                <h3 className="text-white mb-2 font-normal">Tee tarjous kohteesta</h3>
                                                <div className="mb-5">
                                                    <Form layout="vertical" onFinish={handleBidSubmit} className="flex">
                                                        <Form.Item className="mr-3 mb-0">
                                                            <InputNumber
                                                                min={0}
                                                                size="large"
                                                                value={bidAmount}
                                                                onChange={(value) => setBidAmount(value)}
                                                                className="w-full"
                                                                addonAfter={<span className="text-white">€</span>}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item className="mb-0">
                                                            <Button
                                                                type="primary"
                                                                size="large"
                                                                htmlType="submit"
                                                                loading={bidding}
                                                                className="bg-emerald-500 text-white font-bold"
                                                                disabled={bidding ? true : false}
                                                            >
                                                                Tarjoa
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                    <div className="text-white text-sm">
                                                        Minimikorotus: {auction.minBid} €
                                                    </div>
                                                    {bidFormError && (
                                                        <div className="bg-red-700 text-white px-4 py-2 rounded text-sm">
                                                            {bidFormError}
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                        <div className="text-white p-2 bg-primary-700 rounded">
                                            <div className=" flex justify-between">
                                                <div>
                                                    <div className="text-sm">Tarjouksia</div>
                                                    <div className="text-2xl">{auction.bids.bids.length} kpl</div>
                                                </div>
                                                <div className="text-right">
                                                    <div className="text-sm">Tarjoajia</div>
                                                    <div className="text-2xl">{auction.bids.bidders}</div>
                                                </div>
                                            </div>
                                            {auction.userHasHighestBid && (
                                                <div className="bg-emerald-400 text-black font-normal px-4 py-1 text-lg rounded mt-2 text-center">
                                                    Sinulla on korkein tarjous!
                                                </div>
                                            )}
                                        </div>
                                        {auction.bids.bids.length > 0 && (
                                            <>
                                                <div className="text-white text-sm uppercase mt-5">Tarjoukset</div>
                                                <div className="text-gray-50 p-2 bg-primary-400 border border-primary-700 text-sm max-h-64 overflow-y-auto scrollbar-thumb-primary-200 scrollbar-track-primary-700 scrollbar-thin rounded">
                                                    {auction.bids.bids.map((bid, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex mb-1 pb-1 border-b border-b-primary-600 last:border-none last:mb-0 last:pb-0"
                                                        >
                                                            <div className="font-bold">{bid.amount}€</div>
                                                            <div className="flex-1 text-primary-50 ml-2">
                                                                {new Date(bid.createdAt?.date).toLocaleString(
                                                                    "fi-FI",
                                                                    dateOptions
                                                                )}
                                                            </div>
                                                            <div>{bid.name}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <Spin />
                                )}
                            </>
                        </>
                    )}
                </>
            )}

            {auction.type === "sale" && isAuthenticated && !auction.isUserAuction && (
                <div className="mb-5">
                    {userHasBid && !rejectedBid && (
                        <div className="text-black bg-yellow-400 p-2 rounded mb-2 text-center text-base">
                            Olet tehnyt <strong>{auction.bids?.bids[0]?.amount}</strong>&euro; tarjouksen tästä
                            kohteesta
                        </div>
                    )}
                    {userHasBid && rejectedBid && (
                        <div className="text-black bg-yellow-400 p-2 rounded mb-2 text-center text-base">
                            Tarjouksesi kohteesta on hylätty.
                            <br />
                            Tee uusi tarjous.
                        </div>
                    )}
                    <>
                        <Form layout="vertical">
                            <Form.Item>
                                <div className="flex">
                                    <InputNumber
                                        min={0}
                                        size="large"
                                        value={bidAmount}
                                        onChange={(value) => setBidAmount(value)}
                                        addonAfter={<span className="text-white">€</span>}
                                    />

                                    <Button
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                        loading={bidding}
                                        className="bg-emerald-500 text-white font-bold ml-2"
                                        disabled={bidding ? true : false}
                                        onClick={confirmOffer}
                                    >
                                        Tee tarjous
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                        {bidFormError && (
                            <div className="bg-red-700 text-white px-4 py-2 rounded text-sm">{bidFormError}</div>
                        )}
                    </>
                </div>
            )}

            {auction.type === "rent" && (
                <div className="mb-5">
                    <Form layout="vertical">
                        <Form.Item>
                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                loading={bidding}
                                className="bg-emerald-500 text-white font-bold w-full"
                                disabled={bidding ? true : false}
                                onClick={confirmOffer}
                            >
                                Vuokraa tämä kohde
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            )}

            <Modal
                title="Vahvista tarjous"
                open={isConfirmModalVisible}
                onOk={handleConfirmBid}
                onCancel={() => setIsConfirmModalVisible(false)}
                okText="Vahvista tarjous"
                cancelText="Peruuta"
            >
                <p>Olet tekemässä tarjousta summalla {bidAmount} €. Muistathan, että tarjous on sitova!</p>
                {auction.userHasHighestBid && (
                    <p className="text-red-600 font-bold">Sinulla on jo korkein tarjous tästä kohteesta.</p>
                )}
            </Modal>
        </>
    );
};

export default AuctionBidInfo;
