import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb } from "antd";

const Breadcrumbs = () => {
    const location = useLocation();

    if (location.pathname === "/") return null;

    const routes = [
        { label: "Etusivu", path: "/" },
        { label: "Kirjaudu", path: "/kirjaudu" },
        { label: "Oma sivu", path: "/oma-sivu" },
        { label: "Ilmoitukset", path: "/oma-sivu/ilmoitukset" },
        { label: "Uusi ilmoitus", path: "/oma-sivu/ilmoitukset/uusi" },
        { label: "Kuvapankki", path: "/oma-sivu/kuvapankki" },
        { label: "Uusi ilmoitus", path: "/uusi-ilmoitus" },
        { label: "Viestit", path: "/oma-sivu/viestit" },
    ];

    const breadcrumbItems = routes
        .filter((route) => location.pathname.includes(route.path))
        .map((route) => (
            {
                title: route.path === "/" ? <Link to={route.path}>{route.label}</Link> : route.label
            }
        ));

    return (
        <div className="container max-w-screen-2xl m-auto py-4">
            <Breadcrumb items={breadcrumbItems} />
        </div>
    );
};

export default Breadcrumbs;
