import React, { useEffect, useState } from "react";
import { Form, Input, Button, Spin, message, Table } from "antd";
import api from "../../services/Api";
import { Link } from "react-router-dom";
import { DeleteFilled } from "@ant-design/icons";

const Areas = () => {
    const [loading, setLoading] = useState(false);
    const [areas, setAreas] = useState([]);

    useEffect(() => {
        const fetchAreas = async () => {
            try {
                setLoading(true);
                const response = await api.getAreas();
                setAreas(response);
            } catch (error) {
                message.error("Virhe ladatessa alueita, yritä uudelleen.");
            } finally {
                setLoading(false);
            }
        };

        fetchAreas();
    }, []);

    const handleDeleteArea = async (areaId) => {
        try {
            await api.deleteArea(areaId);
            setAreas(areas.filter((area) => area.id !== areaId));
        } catch (error) {
            message.error("Virhe poistettaessa aluetta, yritä uudelleen.");
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: "Nimi",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Slug",
            dataIndex: "slug",
            key: "slug",
        },
        {
            title: "",
            key: "actions",
            render: (text, record) => (
                <div className="flex items-center justify-end">
                    <Link
                        to={`/hallinta/alueet/${record.id}`}
                        className="flex items-center bg-primary text-white hover:bg-primary-300 hover:text-white rounded px-2 py-1 transition-all"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-4 mr-2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                            />
                        </svg>
                        Muokkaa
                    </Link>
                    <div className="flex items-center bg-red-700 hover:bg-red-500 text-white rounded px-2 py-1 ml-5 cursor-pointer" type="text" icon={<DeleteFilled />} onClick={() => handleDeleteArea(record.id)}>
                        <DeleteFilled className="mr-2" /> Poista
                    </div>
                </div>
            ),
        },
    ];
    return (
        <div className={"container m-auto max-w-screen-2xl md:px-0 text-base mt-5"}>
            <h1>Alueet</h1>
            {/* <Link to="/hallinta/alueet/0">
                <Button type="primary" className="mb-5">
                    Lisää alue
                </Button>
            </Link> */}
            {loading ? <Spin /> : <Table dataSource={areas} columns={columns} rowKey="id" />}
        </div>
    );
};

export default Areas;
