import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuctionCountdownTimer from "./AuctionCountdownTimer";
import api from "../../services/Api";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";

const AuctionCard = ({ auction }) => {
    const { isAuthenticated, user } = useContext(AuthContext);
    const { showLoginModal } = useContext(ModalContext);
    const [favorite, setFavorite] = useState(auction.isFavorite);
    const [auctionData, setAuctionData] = useState(auction);

    const toggleFavorite = async (e) => {
        e.preventDefault();

        if (isAuthenticated) {
            const response = await api.toggleFavorite(auction.number);

            setFavorite(response.isFavorite);
        } else {
            showLoginModal();
        }
    };

    useEffect(() => {
        if (isAuthenticated && user) {
            fetchAuctionData();
        }
    }, [isAuthenticated, user]);

    const fetchAuctionData = async () => {
        try {
            const response = await api.getAuction(auctionData.number);
            setAuctionData(response);
            setFavorite(response.isFavorite);
        } catch (error) {
            console.error("Error fetching auction data:", error);
        }
    };

    let cardClass =
        "rounded border-gray-300 bg-white border-b-4 border-b-primary flex flex-col shadow-lg hover:shadow-xl mb-5 md:mb-0 relative overflow-hidden transition-all transition-duration-200 hover:scale-102";

    if (auction.status === "sold") {
        cardClass += " grayscale contrast-75 hover:scale-100 cursor-default";
    }

    return (
        <Link
            key={auction.number}
            to={`/kohde/${auction.number}/${auction.slug}`}
            className={auction.status === "sold" ? "relative" : ""}
            onClick={(e) => {
                if (auction.status === "sold") {
                    e.preventDefault();
                }
            }}
        >
            {auction.status === "sold" && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-20 cursor-default">
                    <div className="text-5xl text-red-500 font-extrabold z-10 transform -rotate-6 grayscale-0 uppercase bg-white px-2 rounded">
                        {auction.type === "rent" ? "Vuokrattu" : "Myyty"}
                    </div>
                </div>
            )}

            <div
                className={cardClass}
                style={{
                    height: "425px",
                }}
            >
                <div className="relative">
                    <div
                        className="absolute top-2 left-3 text-white text-3xl cursor-pointer z-10 transition-all hover:text-red-300"
                        onClick={toggleFavorite}
                    >
                        {favorite ? <HeartFilled /> : <HeartOutlined className="" />}
                    </div>
                    {auction.confirmedSale && (
                        <div className="absolute top-0 right-0 bg-green-600 text-white px-6 py-1 text-xs font-bold z-10 rotate-45 translate-x-8 translate-y-8 shadow-sm shadow-gray-600">
                            VARMAT KAUPAT
                        </div>
                    )}
                    <div className="absolute bottom-2 right-2 px-2 text-primary bg-white text-sm flex items-center rounded z-10">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-3 mr-1"
                        >
                            <path
                                fillRule="evenodd"
                                d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                                clipRule="evenodd"
                            />
                        </svg>
                        {auction.property.area}
                    </div>
                    {auction.images.length ? (
                        <div
                            className="w-full rounded-t-md relative"
                            style={{
                                backgroundImage: `url(${auction.images[0].urls.medium})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                height: "200px",
                                width: "auto",
                            }}
                        ></div>
                    ) : (
                        <div
                            className="flex items-center justify-center bg-gray-300 text-gray-500 rounded-t-md"
                            style={{
                                height: "200px",
                                width: "100%",
                                display: "flex",
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-20"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                                />
                            </svg>
                        </div>
                    )}
                </div>
                <div className="flex flex-col h-full p-4">
                    <div className="flex-1">
                        <div className="text-black">
                            <h3 className="text-lg font-medium text-primary mb-2">{auction.property.title}</h3>
                            {auction?.apartmentDescription && (
                                <div className="flex items-center">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="icon icon-tabler icons-tabler-filled icon-tabler-calendar text-primary mr-2"
                                        >
                                            <defs id="defs18" />
                                            <g id="g967" transform="matrix(0.140625,0,0,0.140625,3,3)">
                                                <path
                                                    d="M 51.4,81 H 60 v -9.4 c -4.3,1.4 -7.6,5 -8.6,9.4 z"
                                                    id="path2"
                                                />
                                                <path
                                                    d="M 64,31.6 V 45 h 52 V 12 H 81.9 C 81,21.8 73.7,29.8 64,31.6 Z"
                                                    id="path4"
                                                />
                                                <path
                                                    d="m 76,67 c 0,-1.1 0.9,-2 2,-2 1.1,0 2,0.9 2,2 v 49 h 36 V 49 H 60 V 28 c 9.2,0 16.9,-6.9 17.9,-16 H 12 V 81 H 47.3 C 48.8,72.9 55.8,67 64,67 V 85 H 12 v 31 h 64 z m 8,1 h 28 v 2 H 84 Z m 0,6 h 28 v 2 H 84 Z m 0,6 h 28 v 2 H 84 Z m 0,6 h 28 v 2 H 84 Z m 0,6 h 28 v 2 H 84 Z m 0,6 h 28 v 2 H 84 Z m 0,6 h 28 v 2 H 84 Z m 0,6 h 28 v 2 H 84 Z"
                                                    id="path6"
                                                />
                                                <path
                                                    d="M 120,0 H 8 C 3.6,0 0,3.6 0,8 v 112 c 0,4.4 3.6,8 8,8 h 112 c 4.4,0 8,-3.6 8,-8 V 8 c 0,-4.4 -3.6,-8 -8,-8 z m 0,120 H 8 V 8 h 112 z"
                                                    id="path8"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div>{auction.apartmentDescription}</div>
                                </div>
                            )}
                            <div className="flex items-center">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="icon icon-tabler icons-tabler-filled icon-tabler-calendar text-primary mr-2"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M16 2a1 1 0 0 1 .993 .883l.007 .117v1h1a3 3 0 0 1 2.995 2.824l.005 .176v12a3 3 0 0 1 -2.824 2.995l-.176 .005h-12a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-12a3 3 0 0 1 2.824 -2.995l.176 -.005h1v-1a1 1 0 0 1 1.993 -.117l.007 .117v1h6v-1a1 1 0 0 1 1 -1zm3 7h-14v9.625c0 .705 .386 1.286 .883 1.366l.117 .009h12c.513 0 .936 -.53 .993 -1.215l.007 -.16v-9.625z" />
                                        <path d="M12 12a1 1 0 0 1 .993 .883l.007 .117v3a1 1 0 0 1 -1.993 .117l-.007 -.117v-2a1 1 0 0 1 -.117 -1.993l.117 -.007h1z" />
                                    </svg>
                                </div>
                                <div>
                                    {auction.property.ownershipType === "week" ? (
                                        <>
                                            vk {auction.propertyWeek}
                                            {auction.type === "rent" && <> / {auction.propertyWeekDates}</>}
                                        </>
                                    ) : (
                                        <>
                                            {auction.property.ownershipType === "other" && "Muu omistus"}
                                            {auction.property.ownershipType !== "week" &&
                                                auction.property.ownershipType !== "other" &&
                                                auction.property.ownershipType + " osaomistus"}
                                        </>
                                    )}
                                </div>
                            </div>
                            {auction?.propertyAttributes?.beds && (
                                <div className="flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="icon icon-tabler icons-tabler-filled icon-tabler-bed-flat text-primary mr-2"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M5 8a3 3 0 1 1 -3 3l.005 -.176a3 3 0 0 1 2.995 -2.824" />
                                        <path d="M18 7a4 4 0 0 1 4 4v2a1 1 0 0 1 -1 1h-11a1 1 0 0 1 -1 -1v-5a1 1 0 0 1 1 -1z" />
                                        <path d="M21 15a1 1 0 0 1 0 2h-18a1 1 0 0 1 0 -2z" />
                                    </svg>
                                    <div className="text-black">
                                        {auction.propertyAttributes.beds}+{auction.propertyAttributes.extrabeds}{" "}
                                        vuodepaikkaa
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div id="price" className="flex justify-between">
                        {auction.type === "auction" && (
                            <>
                                {auction.bids?.length > 0 ? (
                                    <div>
                                        <div className="font-bold text-gray-500">{auction.bids.length} tarjousta</div>
                                        <div className="font-bold text-xl text-black">{auction.bids[0].amount} €</div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="font-bold text-gray-500">Lähtöhinta</div>
                                        <div className="font-bold text-xl text-black">{auction.startPrice} €</div>
                                    </div>
                                )}
                            </>
                        )}
                        {auction.type === "sale" && (
                            <div>
                                <div className="font-bold text-gray-500">Myyntihinta</div>
                                <div className="font-bold text-xl text-black">{auction.startPrice} €</div>
                            </div>
                        )}
                        {auction.type === "rent" && (
                            <div>
                                <div className="font-bold text-gray-500">Vuokrahinta</div>
                                <div className="font-bold text-xl text-black">{auction.startPrice} € / vko</div>
                            </div>
                        )}
                        {auction.type === "auction" && (
                            <div>
                                <div className="font-bold text-gray-500">Aikaa jäljellä</div>
                                <div className="font-bold text-lg text-black time-left text-right">
                                    <AuctionCountdownTimer auction={auction} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default AuctionCard;
