import React, { useState, useEffect } from "react";
import { Select, Button, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import api from "../../services/Api";

const { Option } = Select;

const AuctionSearchBar = ({ onSearch }) => {
    const [type, setType] = useState(["sale"]);
    const [areas, setAreas] = useState([]);
    const [loadingAreas, setLoadingAreas] = useState(false);
    const [selectedAreas, setSelectedAreas] = useState([]);
    const navigate = useNavigate();

    // Auction type options
    const typeOptions = [
        { label: "Myytävät", value: "sale" },
        { label: "Vuokrattavat", value: "rent" },
        { label: "Huutokaupat", value: "auction" },
    ];

    // Fetch areas on component mount
    useEffect(() => {
        const fetchAreas = async () => {
            setLoadingAreas(true);

            try {
                const response = await api.getAvailableAreasAuctions();
                setAreas(response);
            } catch (error) {
                console.error("Error fetching areas:", error);
            } finally {
                setLoadingAreas(false);
            }
        };

        fetchAreas();
    }, []);

    // Handle changes to the auction type
    const handleTypeChange = (value) => {
        setType(value);
    };

    // Handle changes to selected areas
    const handleAreaChange = (value) => {
        setSelectedAreas(value);
    };

    // Handle the search button click
    const handleSearch = () => {
        const params = new URLSearchParams();
        if (type) params.append("t", type);
        if (selectedAreas.length > 0) params.append("a", selectedAreas.join(","));

        navigate(`/kohteet?${params.toString()}`);
    };

    return (
        <div className="flex flex-col md:flex-row gap-4 bg-white rounded-lg p-5 shadow-md items-center justify-center">
            <Select
                value={type}
                onChange={handleTypeChange}
                className="w-full md:w-1/4"
                size="large"
                mode="multiple"
                maxTagCount={"responsive"}
            >
                {typeOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </Select>

            <Select
                mode="multiple"
                value={selectedAreas}
                onChange={handleAreaChange}
                allowClear
                className="w-full md:w-1/3"
                size="large"
                maxTagCount={"responsive"}
                placeholder="Valitse alue"
            >
                {loadingAreas && (
                    <Option value="loading" disabled>
                        <Spin />
                    </Option>
                )}
                {areas?.map((area) => (
                    <Option key={area.area} value={area.area} disabled={area.auctions === 0}>
                        {area.area}
                    </Option>
                ))}
            </Select>

            <Button type="primary" size="large" onClick={handleSearch} className="w-full md:w-1/12">
                Hae
            </Button>
        </div>
    );
};

export default AuctionSearchBar;
